import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Form, FormCheck, Table } from 'react-bootstrap';
import Select from 'react-select';
import CloseIcon from "../assets/img/close.png";
import Icon from './Icon';

interface MultiSelectProps {
    options: any
    placeholder: string
    word?: string
    selectedOptions: any[]
    setSelectedOptions: Dispatch<SetStateAction<any[]>>
    labelText?: string
    centralized?: boolean
    isFilter?: boolean
    withList?: boolean
}

export default function MultiSelect({ options, placeholder, word, selectedOptions, setSelectedOptions, labelText, centralized, isFilter, withList }: MultiSelectProps) {

    const [filteredOptions, setFilteredOptions] = useState(options);

    function handleSelectChange(updateValue: any) {
        if (isFilter) {
            const newItemIndex = updateValue.findIndex((item: any, index: number) => index === updateValue.length - 1);
            if (newItemIndex !== -1) {
                updateValue[newItemIndex].filled = false;
                updateValue[newItemIndex].value = "";
            }

            setSelectedOptions(updateValue);
        } else {
            setSelectedOptions(updateValue);
        }
    }

    function handleRemoveOption(value: any) {
        setFilteredOptions(filteredOptions.filter((option: any) => option.label !== value));
        setSelectedOptions(selectedOptions.filter((option) => option.label !== value));
    }

    function handleFilterChange(value: any, label: string) {
        const updatedOptions = [...selectedOptions]
        const index = updatedOptions.findIndex((item) => item.label === label)

        if (index !== -1) {
            updatedOptions[index].filled = value
            setSelectedOptions(updatedOptions)
        }
    }

    // Função para atualizar o estado de 'filled' quando o input é modificado
    function handleInputChange(e: any, label: string) {
        const updatedOptions = [...selectedOptions];
        const index = updatedOptions.findIndex((item) => item.label === label);

        if (index !== -1) {
            updatedOptions[index].value = e.target.value;
            updatedOptions[index].filled = false; // Desabilitar a checkbox se o input tiver valor
            setSelectedOptions(updatedOptions);
        }
    }

    useEffect(() => {
        let filtered
        if (isFilter) {
            filtered = options.filter((option: any) => !selectedOptions.some((selected) => selected.label === option.label));
        } else {
            filtered = options.filter((option: any) => !selectedOptions.some((selected) => selected.value === option.value));
        }
        setFilteredOptions(filtered);
    }, [selectedOptions, options]);

    return (
        <>
            {labelText && <h5>{labelText}</h5>}
            <div className='select-with-list-component'>
                <Select
                    closeMenuOnSelect={false}
                    isMulti
                    options={filteredOptions}
                    placeholder={placeholder}
                    value={selectedOptions}
                    onChange={handleSelectChange}
                    maxMenuHeight={200}

                />
                {!!withList &&
                    <>
                        {!!word && <h4 className='mt-4'>{word} selecionadas:</h4>}
                        <div className='table-selected-itens'>
                            <Table className={`${!!centralized ? 'centralized-table' : ''}${!!isFilter ? 'search-table' : ''}`}>
                                <thead>
                                    <tr>
                                        <th>Atributo</th>
                                        {isFilter &&
                                            <>
                                                <th>Valor</th>
                                                <th>Possui</th>
                                            </>}
                                        <th className={`${!isFilter && 'text-end'}`}>Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!!selectedOptions && selectedOptions.map((option) => (
                                        <tr key={option.label}>
                                            <td>{option.label}</td>
                                            {isFilter &&
                                                <>
                                                    <td>
                                                        <Form>
                                                            <Form.Group>
                                                                <Form.Control
                                                                    size="sm"
                                                                    required
                                                                    name="name"
                                                                    className="inputBorderBottom"
                                                                    defaultValue={option.value}
                                                                    onChange={(e) => { handleInputChange(e, option.label) }}
                                                                    placeholder="valor..."
                                                                />
                                                            </Form.Group>
                                                        </Form>
                                                    </td>
                                                    <td className='text-center'>
                                                        <FormCheck
                                                            disabled={option.value.length ? true : false}
                                                            checked={option.filled}
                                                            onChange={(e) => handleFilterChange(e.target.checked, option.label)}
                                                        />
                                                    </td>
                                                </>}
                                            <td className='text-end'>
                                                <Button onClick={() => handleRemoveOption(option.label)}>
                                                    <Icon icon={CloseIcon} size='16px' className='close-icon' />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </>
                }
            </div>
        </>
    );
}