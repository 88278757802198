import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import ResetPassword from "./components/ResetPassword";
import Logo from "../../components/Logo";

const ResetPasswordPage = () => (
  <React.Fragment>
    <Helmet title="Recuperar senha" />

    <div className="block-max-width mx-auto">
      <Logo />
      <div className="text-left mt-5">
        <h2>Recuperação de senha</h2>
        <p className="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integger mattis massa vel luctus pharetra</p>
      </div>
      <ResetPassword />
      
    </div>
  </React.Fragment>
);

export default ResetPasswordPage;
