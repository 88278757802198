import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { alertType } from "../../../beans/enumerators";
import CustomAlert from "../../../components/Alert";
import PaginationCustom from "../../../components/lists/Pagination";
import useLoadingContext from "../../../utils/hooks/useLoading";
import DashBoardSideBars from "../components/DashBoardSideBars";
import ModalUserComponent from "./components/ModalUserComponent";
import UserInterface from "./interfaces/user";

const LayerPage = () => {
    // localStorage.setItem('usuarios', JSON.stringify([
    //     { id: 1, name: 'Nome0', user: 'usuario0', email: 'email0@example.com' , password:"1234" },
    //     { id: 2, name: 'Nome1', user: 'usuario1', email: 'email1@example.com' , password:"1234" },
    //     { id: 3, name: 'Nome2', user: 'usuario2', email: 'email2@example.com' , password:"1234" },
    //     { id: 4, name: 'Nome3', user: 'usuario3', email: 'email3@example.com' , password:"1234" },
    //     { id: 5, name: 'Nome4', user: 'usuario4', email: 'email4@example.com' , password:"1234" },
    //     { id: 6, name: 'Nome5', user: 'usuario5', email: 'email5@example.com' , password:"1234" },
    //     { id: 7, name: 'Nome6', user: 'usuario6', email: 'email6@example.com' , password:"1234" },
    //     { id: 8, name: 'Nome7', user: 'usuario7', email: 'email7@example.com' , password:"1234" },
    //     { id: 9, name: 'Nome8', user: 'usuario8', email: 'email8@example.com' , password:"1234" },
    //     { id: 10, name: 'Nome9', user: 'usuario9', email: 'email9@example.com', password:"1234"  },
    // ]))


    const [nList, setNList] = useState<number>(0);
    const [pagination, setPagination] = useState<number>(1);
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [openAlert, setOpenAlert] = useState<boolean>(false)
    const [selectedItem, setSelectedItem] = useState<any>('')
    const [usersList, setUsersList] = useState<Array<UserInterface>>()
    const [showSidebar, setShowSidebar] = useState(true);
    const [filters, setFilters] = useState<any>({
        pagination: "1",
    });

    const { setIsLoading } = useLoadingContext();

    function verifyFilters(key: string, newValue: string) {
        let newFilters = filters;
        newFilters[key] = newValue;
        if (key !== "pagination") newFilters["pagination"] = "1";
        setFilters(newFilters);
        screenUpdate()
    }

    useEffect(() => {
        screenUpdate()
    }, [])

    function screenUpdate() {
        setIsLoading(true)
        let usuarios = JSON.parse(localStorage.getItem('usuarios')!)
        setIsLoading(false)
        if (usuarios) {
            setNList(usuarios.length)
            setUsersList(usuarios)
        }
    }

    function handleDelete() {
        setIsLoading(true);
        const updatedUsers = usersList!.filter(user => user.id !== selectedItem);
        localStorage.setItem('usuarios', JSON.stringify(updatedUsers));
        setUsersList(updatedUsers);
        setIsLoading(false);
        setOpenAlert(false);
        setSelectedItem('');
    }

    function handleCloseAlert() {
        setOpenAlert(false)
        setSelectedItem('')
    }

    return (
        <>
            <Helmet title="Usuários" />
            <Row className="dashboard" >
                <DashBoardSideBars
                    navigateTo={'usuarios'}
                    setShowSidebar={setShowSidebar}
                    showSidebar={showSidebar}
                />
                <Col className={`dashboardCards card col-auto col-lg-10 col-md-9 ${showSidebar ? 'col-sm-9' : 'col-sm-11'} dashboardBody listSize`}>
                    <Row>
                        <Col className="col-10 col-sm-7 col-md-9 col-lg-10">
                            <h2>Tabela de usuários</h2>
                        </Col>
                        <Col className="m-xs-4 col-5 col-md-3 col-lg-2">
                            <Button className="primary-btn" onClick={() => setOpenModal(true)}>ADICIONAR</Button>
                        </Col>
                    </Row>
                    <Table className="mt-5 trBorder">
                        <thead>
                            <tr >
                                <th>Nome</th>
                                <th>Usuário</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!!usersList && usersList.map((item, key) => (
                                <tr className="trBorder" key={key}>
                                    <td>{item.name}</td>
                                    <td>{item.user}</td>
                                    <td>{item.email}</td>
                                    <td className="d-flex justify-content-end">
                                        <FontAwesomeIcon icon={faTrash} className="align-middle mx-2" onClick={() => { setOpenAlert(true); setSelectedItem(item.id) }} />
                                        <FontAwesomeIcon icon={faPencilAlt} className="align-middle" onClick={() => { setOpenModal(true); setSelectedItem(item.id) }} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    <PaginationCustom
                        pagination={pagination}
                        filter={filters.pagination}
                        verifyFilters={verifyFilters}
                        total={nList}
                    />


                </Col>
            </Row >
            <CustomAlert
                message={alertType.delete + 'esse usuário?'}
                show={openAlert}
                onYes={handleDelete}
                onNo={handleCloseAlert}
                onHide={handleCloseAlert}
            />

            <ModalUserComponent
                openModal={openModal}
                setOpenModal={setOpenModal}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                screenUpdate={screenUpdate}
            />
        </>

    );
};

export default LayerPage;
