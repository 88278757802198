import React from "react";
import { Helmet } from "react-helmet-async";

import SignIn from "./components/SignIn";
import Logo from "../../components/Logo";

const SignInPage = () => (
  <React.Fragment>
    <Helmet title="Entrar" />

    <div className="block-max-width mx-auto">
      <Logo />
      <div className="text-left mt-5">
        <h2>Acesse o Sistema</h2>
        <p className="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integger mattis massa vel luctus pharetra</p>
      </div>
      <SignIn />
    </div>
  </React.Fragment>
);

export default SignInPage;
