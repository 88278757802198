import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import MultiSelect from '../../../components/SelectSearch';
import MapSideBar from '../components/MapSideBar';
import { FilterListInterface } from './interfaces';
import { getFilterList } from './services';
import useLoadingContext from '../../../utils/hooks/useLoading';

interface FiltersSidebarProps {
    selectedFilters: Array<any>
    setSelectedFilters: Dispatch<SetStateAction<any>>
    haveFilter: boolean
    setHaveFilter: Dispatch<SetStateAction<boolean>>
}

const FiltersSidebar = ({ selectedFilters, setSelectedFilters, setHaveFilter }: FiltersSidebarProps) => {
    const { setIsLoading } = useLoadingContext();
    const [filterList, setFilterList] = useState<Array<FilterListInterface>>([])

    useEffect(() => {
        getFilterList().then((response) => {
            setFilterList(response)
        }).catch(() => {
            setIsLoading(false)
        })
    }, [])

    return (
        <MapSideBar title={"Filtros"}>
            <>
                <React.Fragment>
                    <Row className="defaultSidebar mx-2">
                        <Col lg={12} className="default-header">
                            <h4 className='default-title'>Propriedades</h4>
                        </Col>
                        <MultiSelect
                            options={filterList}
                            placeholder="Selecione os filtros"
                            selectedOptions={selectedFilters}
                            setSelectedOptions={setSelectedFilters}
                            isFilter
                            withList
                        />

                        {!!selectedFilters.length &&
                            <Col className="filterSidebar-button">
                                <Button className="primary-btn modalSubmi-btn" onClick={() => setHaveFilter(true)}>
                                    FILTRAR
                                </Button>
                            </Col>}
                    </Row>
                </React.Fragment>
            </>
        </MapSideBar >

    );
};

export default FiltersSidebar;
