import { DefaultGeometryType, GeometryTypeShortedEnum } from './../beans/enumerators';


export function checkGeometry(geometry: GeometryTypeShortedEnum) {
    switch (geometry) {
        case "G":
            return DefaultGeometryType.polygon
        case "L":
            return DefaultGeometryType.line
        case "P":
            return DefaultGeometryType.point
    }
}

export function invertedCheckGeometry(geometry: string) {
    switch (geometry) {
        case "Marker":
        case "marker":
            return GeometryTypeShortedEnum.P
        case "Polygon":
        case "polygon":
            return GeometryTypeShortedEnum.G
        case "Polyline":
        case "polyline":
            return GeometryTypeShortedEnum.L
    }
}
