import { relatoryApi } from "../../../../utils/axios";
import { ListResponse, SuccessResponse } from "../../../../utils/interfaces/response";
import { verifyData, verifyDataPagination } from "../../../../utils/verify-response";
import UserGroupInterface from "../interfaces/userGroup";

export async function getUserGroupByID(id: number) {
   return await relatoryApi.get('/userGroup/find?id=' + id).then(response => {
      return verifyData(response);
   })
}

export async function getUserGroups(data: any) {
   return await relatoryApi.get('/userGroup?' + new URLSearchParams(data).toString()).then(response => {
      return verifyDataPagination(response);
   })
}

export async function getUserGroupsList(): Promise<Array<ListResponse>> {
   return await relatoryApi.get('/userGroup/list-to-select').then(response => {
      return response.data;
   })
}

export async function createUserGroup(data: UserGroupInterface): Promise<SuccessResponse> {
   return await relatoryApi.post('/userGroup/', data).then(response => {
      return response.data
   })
}

export async function updateUserGroup(id: number, data: UserGroupInterface): Promise<SuccessResponse> {
   return await relatoryApi.put(`/userGroup?id=${id}`, data).then(response => {
      return response.data
   })
}

export async function deleteUserGroup(id: number): Promise<SuccessResponse> {
   return await relatoryApi.delete('/userGroup?id=' + id).then(response => {
      return response.data;
   })
}
