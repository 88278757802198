import { Card, Col, Row } from "react-bootstrap";

import { Item } from "../interfaces";

interface SecondLineProps {
    name?: string
    description?: string
    items?: Array<Item>
}

export function SecondLine({ name, description, items }: SecondLineProps) {
    return (
        <>
            <Col lg={4}>
                <Card className="secondLineCards">
                    <span className="cardTitle">{name}</span>
                    <p className="cardSubtitle">{description}</p>
                    <Row className="graphic">
                        {!!items?.length && items.map((item, key) => {
                            return (
                                <Col key={`graphic-${key}`} lg={4} className="bar" style={{ width: `${item.value}%` }}>&nbsp;</Col>
                            )
                        })}
                    </Row>
                    <div className="mx-3">
                        {!!items?.length && items.map((item, key) => {
                            return (
                                <div className="graphicDescription" key={`description-${key}`}>
                                    <div className="circleContainer">
                                        <div className={`circle color${key + 1}`}></div>
                                        <span className="mx-3 text-left">{item.label}</span>
                                    </div>
                                    <span className="text-right">{item.value}%</span>
                                </div>
                            )
                        })}
                    </div>
                </Card>
            </Col>
        </>
    )
}