import React from "react";

interface AvatarProps {
  src?: string;
  size?: "big" | "small"
  name?: string;
  showName?: boolean;
  alt?: string
}

const Avatar = ({ src, name, showName, size, alt }: AvatarProps) => {
  return (
    <>
      <img
        src={src ? src : "https://ui-avatars.com/api/?background=0524C8&color=fff" +
          (size === "big" ? "&size=100" : size === "small" ? "&size=100" : "") + "&name=" + name
        }
        className={(size === "big" ? "avatar-big" : size === "small" ? "avatar-small" : "avatar") + " img-fluid rounded-circle"}
        alt={alt ? alt : ''}
      />
      {showName && <span className="ps-2 text-dark">{name}</span>}
    </>
  );
};

export default Avatar;
