import { Formik } from "formik";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { GeometryType, GeometryTypeShortedEnum, alertType } from "../../../../beans/enumerators";
import FileUploadComponent from "../../../../components/FileUploader";
import PageTitle from "../../../../components/PageTitle";
import useLoadingContext from "../../../../utils/hooks/useLoading";
import usePoolingVerifierContext from "../../../../utils/hooks/usePoolingVerifier";
import { ListResponse } from "../../../../utils/interfaces/response";
import { LayerInterface } from "../interfaces";
import { createLayer, getLayerByID, getLayersList, getLayersStyleList, importShapes, updateLayer } from "../services/layers";
interface ModalLayerComponentProps {
    openModal: boolean
    setOpenModal: Dispatch<SetStateAction<boolean>>
    screenUpdate: () => void
    setSelectedItem: Dispatch<any>
    selectedItem?: any
}

const ModalLayerComponent: React.FC<ModalLayerComponentProps> = ({ openModal, setOpenModal, selectedItem, setSelectedItem, screenUpdate }) => {

    const { setValue } = usePoolingVerifierContext();

    const [layerSelectList, setLayerSelectList] = useState<Array<ListResponse>>([])
    const [layerStyleList, setLayerStyleList] = useState<Array<ListResponse>>([])
    const [files, setFiles] = useState<Array<File>>([])
    const { setIsLoading } = useLoadingContext();
    const [initialValues, setInitialValues] = useState<LayerInterface>({
        name: undefined,
        geometryType: undefined,
        company: undefined,
        layerStyle: undefined,
        fatherLayerId: undefined
    })

    useEffect(() => {
        handleReloadLayerList()
    }, [])

    async function handleReloadLayerList() {
        await getLayersList().then((response) => { setLayerSelectList(response) }).catch(() => {
            setIsLoading(false)
        })
    }

    async function handleGetLayerStyleList(type: GeometryTypeShortedEnum) {
        await getLayersStyleList(type).then((response) => { setLayerStyleList(response.data) }).catch(() => {
            setIsLoading(false)
        })
    }


    async function handleCreate(values: LayerInterface) {
        setIsLoading(true)
        await createLayer(values).then(async (response) => {
            if (files.length) {
                const formData = new FormData();
                files.forEach((file) => {
                    formData.append("files", file);
                });
                await importShapes(String(response.id), formData).then((response) => {
                    if (response.protocolId) {
                        setValue(response.protocolId)
                        toast.success(alertType.polling)
                    }
                }).catch(() => {
                    toast.error(alertType.fileError)
                    setIsLoading(false)
                })
            } else {
                toast.error(alertType.fileError)
                setIsLoading(false)
            }
            handleReloadLayerList()
        }).catch(() => {
            setIsLoading(false)
            toast.error(alertType.error)
        })

        handleCloseModal()
        screenUpdate();
        setIsLoading(false)
    }

    async function handleEdit(values: LayerInterface) {
        setIsLoading(true)
        await updateLayer(selectedItem, values).then(() => {
            handleReloadLayerList()
            toast.success(alertType.updated)
        }).catch(() => {
            setIsLoading(false)
        })
        handleCloseModal()
        screenUpdate()
        setIsLoading(false)
    }

    useEffect(() => {
        if (selectedItem) {
            getLayerByID(selectedItem).then((response) => {
                if (response) {
                    let data: LayerInterface = {
                        name: response.name,
                        geometryType: response.geometryType,
                        company: response.company,
                        layerStyle: response.layerStyle,
                        fatherLayerId: response.fatherLayerId
                    }
                    setInitialValues(data)
                    handleGetLayerStyleList(response.geometryType)
                }
            }).catch(() => {
                setIsLoading(false)
            })
        }
    }, [selectedItem])

    function handleCloseModal() {
        setOpenModal(false)
        setSelectedItem('')
        setInitialValues({})
        setFiles([])
    }

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={
                    Yup.object().shape({
                        name: Yup.string(),
                        geometryType: Yup.string(),
                        layerStyle: Yup.number().nullable(),
                        fatherLayerId: Yup.number().nullable()
                    })
                }
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    if (selectedItem) {
                        handleEdit(values)
                    } else {
                        handleCreate(values)
                    }
                    resetForm({ values: { ...initialValues } });

                }}>{({ handleBlur, handleChange, handleSubmit, isSubmitting, values, errors }) => (
                    < Modal dialogClassName="ts-modal" show={openModal} onHide={handleCloseModal} >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <PageTitle text={`${!!selectedItem ? 'Editar' : 'Adicionar'} Camada`} />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body  >
                            <Form onSubmit={handleSubmit} className="px-2" >
                                <Form.Group className="mb-3">
                                    <Form.Label>Nome da camada</Form.Label>
                                    <Form.Control
                                        required
                                        size="lg"
                                        name="name"
                                        className="inputBorderBottom"
                                        value={values.name}
                                        onChange={handleChange}
                                        placeholder="Nome"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3 text-start ">
                                    <Form.Label>Tipo</Form.Label>
                                    <Form.Select
                                        disabled={!!selectedItem}
                                        required
                                        size="lg"
                                        name="geometryType"
                                        className="inputBorderBottom"
                                        value={values.geometryType}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            handleGetLayerStyleList(e.target.value as GeometryTypeShortedEnum)
                                            handleChange(e)
                                        }}
                                    >
                                        <option key="" value="">Selecione</option>
                                        {GeometryType.map((item, key) => {
                                            return (<option key={key} value={item.value}>{item.text}</option>)
                                        })}
                                    </Form.Select>
                                </Form.Group>
                                {/* <Form.Group className="mb-3">
                                    <Form.Label>Company</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="company"
                                        className="inputBorderBottom"
                                        value={values.company}
                                        onChange={handleChange}
                                        placeholder="Empresa"
                                    />
                                </Form.Group> */}

                                <Form.Group className="mb-3 text-start ">
                                    <Form.Label>Camada pai</Form.Label>
                                    <Form.Select
                                        size="lg"
                                        name="fatherLayerId"
                                        className="inputBorderBottom"
                                        value={values.fatherLayerId}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    >
                                        <option key="" value="">Selecione</option>
                                        {!!layerSelectList && layerSelectList.map((item, key) => {
                                            return (<option key={key} value={Number(item.value)}>{item.label}</option>)
                                        })}
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3 text-start ">
                                    <Form.Label>Estilo da camada</Form.Label>
                                    <Form.Select
                                        size="lg"
                                        name="layerStyle"
                                        className="inputBorderBottom"
                                        value={values.layerStyle}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        disabled={!values.geometryType}
                                    >
                                        <option key="" value="">Selecione</option>
                                        {!!layerStyleList && layerStyleList.map((item, key) => {
                                            return (
                                                <option key={key} value={item.value}>{item.label}</option>
                                            )
                                        })}
                                    </Form.Select>
                                    {/* {errors.layerStyle && (
                                        <p className="p-form-error">{errors.}</p>
                                    )} */}
                                </Form.Group>

                                {!selectedItem &&
                                    <Form.Group className="mb-3 filesContainer">
                                        <Form.Label>Selecione os Arquivos</Form.Label>
                                        <FileUploadComponent
                                            setFiles={setFiles}
                                        />
                                    </Form.Group>
                                }
                                <Row className="modal-newlayer-footer ">
                                    <hr />
                                    <Col className="d-flex justify-content-end">
                                        <Button className="redbg ts-link-icon mx-2" onClick={handleCloseModal}> CANCELAR</Button>
                                        <Button
                                            type="submit"
                                            className="primary-btn modalSubmi-btn"
                                            disabled={isSubmitting}
                                        >
                                            {selectedItem ? 'EDITAR' : 'CRIAR'}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal.Body>
                    </Modal >
                )}
            </Formik>
        </>

    )
}

export default ModalLayerComponent;