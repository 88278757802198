import { BBox, GeoJsonProperties } from 'geojson';
import L from "leaflet";
import { useCallback, useEffect, useState } from 'react';
import { Marker, Polygon, Polyline, useMap, } from "react-leaflet";
import { ClusterProperties, PointFeature } from "supercluster";
import useSupercluster from "use-supercluster";
import { DefaultLayerStyle, GeometryTypeEnum, mapSideBarContextEnum } from "../../../beans/enumerators";
import useLoadingContext from '../../../utils/hooks/useLoading';
import useSideBarContext from "../../../utils/hooks/useSideBarMap";
import { FeatureCollection, FeatureEntity } from '../interfaces';
import ShapeSideBar from "./ShapeSideBar";
import cuid from 'cuid';

interface ShowShapesProps {
  shapesList: FeatureCollection;
  getShapes: () => void
}

function ShowShapes({ shapesList, getShapes }: ShowShapesProps) {

  const { setIsLoading } = useLoadingContext();
  const { value, setValue } = useSideBarContext();
  const maxZoom = 22;
  const map = useMap();
  const bounds = [
    map.getBounds().getSouth(),
    map.getBounds().getWest(),
    map.getBounds().getNorth(),
    map.getBounds().getEast()
  ] as BBox
  const [selectedShape, setSelectedShape] = useState<number>()
  const [zoom, setZoom] = useState(3);
  const icons: any = {};

  const updateMap = useCallback(() => {
    setZoom(map.getZoom());
    setIsLoading(false)
  }, [map]);

  useEffect(() => {
    updateMap()
  }, []);

  useEffect(() => {
    map.on("move", updateMap);

    return () => {
      map.off("move", updateMap)
    }
  }, [map]);



  const fetchIcon = (count: number, size: number) => {
    if (!icons[count]) {
      icons[count] = L.divIcon({
        html: `<div class="cluster-marker" style="width: ${size}px; height: ${size}px;">
          ${count}
        </div>`,
      });
    }
    return icons[count];
  };

  const pointShapes: Array<FeatureEntity> = []
  const otherShapes = shapesList.features && shapesList.features.filter((shape, key) => {
    if (shape.feature.geoJson.type === "Point") {
      pointShapes.push(shape)
      return false
    } else {
      return true
    }
  })

  const points: Array<PointFeature<GeoJsonProperties>> = pointShapes && pointShapes.map((shape, key) => ({
    type: "Feature" as const,
    properties: { cluster: false },
    geometry: { type: "Point", coordinates: shape.feature.geoJson.coordinates },
    id: shape.feature.properties.id
  }));

  // Configurações para o supercluster
  const { clusters, supercluster } = useSupercluster({
    points: points,
    bounds: bounds,
    zoom: zoom,
    options: { radius: 50, maxZoom: 17 },
  });

  return (
    <>
      {!!clusters && clusters.map((cluster, key) => {
        const { cluster: isCluster, point_count: pointCount, cluster_id } = cluster.properties as ClusterProperties;
        const [longitude, latitude] = cluster.geometry.coordinates;

        if (isCluster) {
          //CLUSTER
          return (
            <Marker
              key={cuid()}
              position={[longitude, latitude]}
              icon={fetchIcon(
                pointCount,
                10 + ((pointCount) / (points.length)) * 40 + 20
              )}
              eventHandlers={{
                click: () => {
                  const expansionZoom = Math.min(
                    supercluster?.getClusterExpansionZoom(cluster_id) || 0,
                    maxZoom
                  );
                  map.setView([longitude, latitude], expansionZoom, {
                    animate: true,
                  });
                },
              }}
            />
          );
        } else {
          //PONTO
          var iconUrl = "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png"
          if (pointShapes[key] && pointShapes[key].style) {
            const { caminhoaws, caminhohttps } = pointShapes[key].style
            iconUrl = caminhoaws ? caminhoaws : caminhohttps ? caminhohttps : "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png"
          }
          const cuffs = new L.Icon({
            iconUrl: iconUrl,
            iconSize: [25, 35],
          });

          return (
            <Marker
              key={cuid()}
              position={[longitude, latitude]}
              icon={cuffs}
              eventHandlers={{
                click: () => {
                  setValue(mapSideBarContextEnum.shape)
                  setSelectedShape(Number(cluster.id))
                }
              }}
            />
          );
        }
      })}

      {!!otherShapes && otherShapes.map((shape, key) => {
        var style = shape.style 

        if (shape.feature.geoJson.type === GeometryTypeEnum.polygon) {
          // POLÍGONO
          return (
            <Polygon
              color={style ? style.color : DefaultLayerStyle.color}
              opacity={style ? style.colorOpacity : DefaultLayerStyle.colorOpacity}
              fillColor={style ? style.filledColor : DefaultLayerStyle.filledColor}
              fillOpacity={style ? style.filledColorOpacity : DefaultLayerStyle.filledColorOpacity}
              weight={style ? style.lineHeight : DefaultLayerStyle.lineHeight}
              key={cuid()}
              positions={shape.feature.geoJson.coordinates[0]}
              eventHandlers={{
                click: () => {
                  setValue(mapSideBarContextEnum.shape);
                  setSelectedShape(Number(shape.feature.properties.id));
                },
              }}
            />
          );
        } else if (shape.feature.geoJson.type === GeometryTypeEnum.multiPolygon) {
          // MULTIPOLÍGONO - IMPORTAÇÃO
          return shape.feature.geoJson.coordinates.map((polygonCoords, index) => (
            <Polygon
              color={style ? style.color : DefaultLayerStyle.color}
              opacity={style ? style.colorOpacity : DefaultLayerStyle.colorOpacity}
              fillColor={style ? style.filledColor : DefaultLayerStyle.filledColor}
              fillOpacity={style ? style.filledColorOpacity : DefaultLayerStyle.filledColorOpacity}
              weight={style ? style.lineHeight : DefaultLayerStyle.lineHeight}
              key={cuid()}
              positions={polygonCoords}
              eventHandlers={{
                click: () => {
                  setValue(mapSideBarContextEnum.shape);
                  setSelectedShape(Number(shape.feature.properties.id));
                },
              }}
            />
          ));
        } else if (shape.feature.geoJson.type === GeometryTypeEnum.line) {
          // LINHA 
          return (
            <Polyline
              color={style ? style.color : DefaultLayerStyle.color}
              opacity={style ? style.colorOpacity : DefaultLayerStyle.colorOpacity}
              weight={style ? style.lineHeight : DefaultLayerStyle.lineHeight}
              key={cuid()}
              positions={shape.feature.geoJson.coordinates}
              eventHandlers={{
                click: () => {
                  setValue(mapSideBarContextEnum.shape);
                  setSelectedShape(Number(shape.feature.properties.id));
                },
              }}
            />
          );
        } else if (shape.feature.geoJson.type === GeometryTypeEnum.multiLineString) {
          // MULTILINESTRING - IMPORTAÇÃO
          return shape.feature.geoJson.coordinates.map((lineCoords, index) => (
            <Polyline
              color={style ? style.color : DefaultLayerStyle.color}
              opacity={style ? style.colorOpacity : DefaultLayerStyle.colorOpacity}
              weight={style ? style.lineHeight : DefaultLayerStyle.lineHeight}
              key={cuid()}
              positions={lineCoords}
              eventHandlers={{
                click: () => {
                  setValue(mapSideBarContextEnum.shape);
                  setSelectedShape(Number(shape.feature.properties.id));
                },
              }}
            />
          ));
        }
      })}
      {!!selectedShape && value === mapSideBarContextEnum.shape &&
        <ShapeSideBar
          selectedShape={selectedShape}
          setSelectedShape={setSelectedShape}
          setValue={setValue}
          getShapes={() => getShapes()}
        />}

    </>
  );
}

export default ShowShapes;
