import { GeometryTypeShortedEnum } from "../../../../beans/enumerators";
import shapeApi from "../../../../utils/axios";
import { ListResponse, SuccessResponse } from "../../../../utils/interfaces/response";
import { verifyData, verifyDataPagination } from "../../../../utils/verify-response";
import { PoolingResponse } from "../../Polling/interfaces";
import { LayerInterface } from "../interfaces";

export async function getLayerByID(id: number) {
	return await shapeApi.get('/layer/findById?id=' + id).then(response => {
		return verifyData(response);
	})
}

export async function getLayers(data: any) {
	return await shapeApi.get('/layer/find?' + new URLSearchParams(data).toString()).then(response => {
		return verifyDataPagination(response);
	})
}

export async function getLayersList(arrayLayers?: Array<number>): Promise<Array<ListResponse>> {
	return await shapeApi.post('/layer/list-to-select/', arrayLayers).then(response => {
		return response.data;
	})
}

export async function getLayersListChilds(arrayLayers?: Array<number>): Promise<Array<ListResponse>> {
	return await shapeApi.post('/layer/list-to-child/', arrayLayers).then(response => {
		return response.data;
	})
}

export async function createLayer(data: LayerInterface): Promise<SuccessResponse> {
	return await shapeApi.post('/layer/create/', data).then(response => {
		return response.data
	})
}

export async function updateLayer(id: number, data: LayerInterface): Promise<SuccessResponse> {
	return await shapeApi.put('/layer/update?id=' + id, data).then(response => {
		return response.data
	})
}

export async function importShapes(id: string, data: FormData): Promise<PoolingResponse> {
	return await shapeApi.post(`/shape/import-shape/create?layer=${id}`, data).then(response => {
		return response.data;
	})
}

export async function deleteLayer(id: number): Promise<SuccessResponse> {
	return await shapeApi.delete('/layer/delete?id=' + id).then(response => {
		return response.data;
	})
}

export async function getLayersStyleList(type: GeometryTypeShortedEnum) {
	return await shapeApi.get('/layer-style/list-to-select/?type=' + type).then(response => {
		return response;
	})
} 