import "leaflet/dist/leaflet.css";
import { PropsWithChildren, useState } from "react";
import { Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { mapSideBarContextEnum } from "../../beans/enumerators";
import useSideBarContext from "../../utils/hooks/useSideBarMap";
import FiltersSidebar from "./Filters/FiltersSidebar";
import LayerSidebar from "./Layers/LayerSidebar";
import OccupationAreaSidebar from "./OccupationArea/OccupationAreaSidebar";
import RelatorySidebar from "./Relatory/RelatorySidebar";
import MapComponent from "./components/MapComponent";

interface MapProps {
    children?: any
}

const Map: React.FC<PropsWithChildren> = ({ children }: MapProps) => {

    const { value } = useSideBarContext();
    const [openDrawOptions, setOpenDrawOptions] = useState<boolean>(false);
    const [checkedLayer, setCheckedLayer] = useState<Array<number>>([])
    const [isLayerDeleted, setIsLayerDeleted] = useState<boolean>(false)
    const [selectedFilters, setSelectedFilters] = useState<Array<any>>([])
    const [haveFilter, setHaveFilter] = useState<boolean>(false)

    return (
        <Row >
            <Helmet title="Mapa" />
            <div>
                {value === mapSideBarContextEnum.occupationArea && <OccupationAreaSidebar />}
                {value === mapSideBarContextEnum.relatories && <RelatorySidebar />}
                {value === mapSideBarContextEnum.layers &&
                    <LayerSidebar
                        checkedLayer={checkedLayer}
                        setCheckedLayer={setCheckedLayer}
                        isLayerDeleted={isLayerDeleted}
                        setIsLayerDeleted={setIsLayerDeleted}
                    />
                }
                {value === mapSideBarContextEnum.filters &&
                    <FiltersSidebar
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        haveFilter={haveFilter}
                        setHaveFilter={setHaveFilter}
                    />}
            </div>

            <MapComponent
                checkedLayer={checkedLayer}
                setCheckedLayer={setCheckedLayer}
                openDrawOptions={openDrawOptions}
                setOpenDrawOptions={setOpenDrawOptions}
                isLayerDeleted={isLayerDeleted}
                setIsLayerDeleted={setIsLayerDeleted}
                selectedFilters={selectedFilters}
                haveFilter={haveFilter}
                setHaveFilter={setHaveFilter}
            />

        </Row>
    )
};

export default Map;
