import React from "react";
import { Button, Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

const Default = () => {
  return (
    <React.Fragment>
      <Helmet title="Em construção" />
      <Container fluid className="p-4">
        <h2>Em construção</h2>
        <p className="lead">Confira mais tarde.</p>
        <Link to="/estatisticas">
          <Button variant="primary">Voltar</Button>
        </Link>
      </Container>
    </React.Fragment>
  );
};

export default Default;
