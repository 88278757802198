import { statusCode } from './../beans/enumerators';
import { AxiosError, AxiosResponse } from "axios";

import { getToken, unsetSession } from './token';
// import useLoadingContext from './hooks/useLoading';


// const { setIsLoading } = useLoadingContext();

export function verifyDataList(response: any): Array<object> {
    verifyStatusCode(response.status);
    if (!response || !response.data) return [];
    if (response.data[0]) return response.data;
    return [];
}

interface PaginationResponse {
    page: number
    result: Array<any>;
    total: number;
}

export function verifyDataPagination(response: any): PaginationResponse {
    verifyStatusCode(response.status);
    if (!response || !response.data || !response.data.result) return { page: 1, result: [], total: 0 };
    if (response.data.result) return response.data;
    return { page: 1, result: [], total: 0 };
}

export function verifyData(response: any) {
    verifyStatusCode(response.status);
    if (!response || !response.data) return undefined;
    if (typeof response.data === 'object') return response.data;
    return undefined;
}


export function verifyResponse(response: AxiosResponse): AxiosResponse {
    verifyStatusCode(response.status);
    if (response.data && response.data.status) return response.data;
    return response;
}


export function verifyError(error: any) {
    let errorMessage = '';
    const { status: statusCode } = error.response
    console.log('status:', statusCode)
    switch (statusCode) {
        case 0:
            errorMessage = 'Ocorreu algum erro no sistema';
            break
        case 400:
            errorMessage = 'A requisição não pôde ser entendida pelo servidor devido a uma sintaxe inválida.';
            break;
        case 401:
            errorMessage = 'A requisição requer autenticação do usuário.';
            break;
        case 403:
            errorMessage = 'O servidor entendeu a requisição, mas se recusa a autorizá-la.';
            break;
        case 404:
            errorMessage = 'O recurso solicitado não foi encontrado.';
            break;
        case 405:
            errorMessage = 'O método solicitado é conhecido pelo servidor, mas não é suportado pelo recurso alvo.';
            break;
        case 408:
            errorMessage = 'O servidor expirou enquanto esperava pela requisição.';
            break;
        case 500:
            errorMessage = 'O servidor encontrou uma condição inesperada que o impediu de atender a requisição.';
            break;
        case 501:
            errorMessage = 'O servidor não suporta a funcionalidade necessária para atender a requisição.';
            break;
        case 502:
            errorMessage = 'O servidor, ao atuar como um gateway ou proxy, recebeu uma resposta inválida do servidor upstream.';
            break;
        case 503:
            errorMessage = 'O servidor está indisponível no momento (sobrecarga ou em manutenção).';
            break;
        case 504:
            errorMessage = 'O servidor, ao atuar como um gateway ou proxy, não recebeu uma resposta a tempo do servidor upstream.';
            break;
        default:
            errorMessage = `Erro desconhecido: Código de status ${statusCode}`;
    }
    // setIsLoading(false)
    return errorMessage;
}

async function verifyStatusCode(code: number) {
    if (code === 401 && getToken()) unsetSession();
}