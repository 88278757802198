import React from 'react';
import ApexCharts from 'react-apexcharts';


interface BarChartProps {
    colors: Array<string>;
}

const BarChart = ({ colors }: BarChartProps) => {
    const options: any = {
        chart: {
            type: 'bar',
            height: 231,
        },
        plotOptions: {
            bar: {
                columnWidth: '80%',
                borderRadius: 2,

            },
        },
        dataLabels: {
            enabled: false,
        },
        yaxis: {
            labels: {
                formatter: (y: any) => y.toFixed(0) + '',
            },
        },
        xaxis: {
            categories: [
                'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            ],
        },
        legend: {
            position: 'bottom',
            fontSize: '10px',
            itemMargin: {
                horizontal: 10,
                vertical: 0
            },
            markers: {
                height: 8,
                width: 13,
                radius: 0,
            }
        },
        stroke: {
            width: 1.5
        },
        colors: colors,
        fill: {
            colors: colors,
            opacity: 0.7,
        },
    };

    const series = [
        {
            name: 'Product 1',
            data: [1, 5, 59, -55, -16, -18],
        },
        {
            name: 'Product 2',
            data: [60, -20, 30, 55, 50, -23],
        },
        {
            name: 'Product 3',
            data: [-5, 10, -15, -55, 25, -2],
        },
    ];

    return (
        <div>
            <ApexCharts options={options} series={series} type="bar" />
        </div>
    );
};

export default BarChart
