import jwtDecode from "jwt-decode";
import moment from "moment";

import axios from "./axios";
import { Token } from "./interfaces/token";

const userToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjYsIm9jY3VwYXRpb25saXN0IjpbMTIyLDEyOCwxMjksMTMwLDEzMSwxMzMsMTM3LDEzOCwxMzksMTQwLDE0MSwxNDIsMTQzLDE0NSwxNDksMTUwLDE1MiwxNTMsMTU0LDE1NSwxNTYsMTU3LDE2M10sImNvbXBhbnkiOjEsImlhdCI6MTUxNjIzOTAyMn0.6AIhS0Um8c0uZ0n75c5-JqC5dd6l3en_BSOk65P0OEY"
const admToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjYsIm9jY3VwYXRpb25saXN0IjpbMTIyLDEyOCwxMjksMTMwLDEzMSwxMzMsMTM3LDEzOCwxMzksMTQwLDE0MSwxNDIsMTQzLDE0NSwxNDksMTUwLDE1MiwxNTMsMTU0LDE1NSwxNTYsMTU3LDE2M10sImlhdCI6MTUxNjIzOTAyMn0.6ZTKh9aPPXqmsv4SwpraOdfW9qV2lijgHvtIkTJ4hNg"

const getToken = () => {
  return localStorage.getItem("accessToken");
};

export function getDecodedToken(): Token | undefined {
  let token = localStorage.getItem("accessToken");
  if (token) {
    return jwtDecode(token)
  }
  return undefined
};

const isValidToken = (accessToken: string | null) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = moment().unix();

  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp: number) => {
  let expiredTimer;
  window.clearTimeout(expiredTimer);

  const currentTime = moment().unix();
  const timeLeft = (exp - currentTime) * 1000;

  expiredTimer = window.setTimeout(() => {
    unsetSession();
  }, timeLeft);
};

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    const { exp } = jwtDecode<{ exp: number }>(accessToken);
    handleTokenExpired(exp);
  } else {
    unsetSession();
  }
};

const unsetSession = () => {
  localStorage.removeItem("accessToken");
  delete axios.defaults.headers.common.Authorization;
  window.location.reload();
};

export { getToken, isValidToken, setSession, unsetSession };