import React from "react";
import { Container, Row, Spinner } from "react-bootstrap";

const Loader = () => (
  <div className="loading-overlay">
    <Container fluid className="d-flex loading-content justify-content-center align-self-center">
      <Row className="justify-content-center align-self-center w-100 text-center ">
        <div className="text-center ">
          <Spinner animation="border" variant="primary" />
          <p className="mt-2">Por favor, aguarde...</p>
        </div>
      </Row>
    </Container>
  </div>
);

export default Loader;
