import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useMap } from 'react-leaflet';
import { toast } from 'react-toastify';
import CloseIcon from "../../../assets/img/close.png";
import ExportIcon from "../../../assets/img/export.png";
import InfoIcon from "../../../assets/img/info.png";
import OpenedThreshIcon from "../../../assets/img/opened-trash.png";
import ThreshIcon from "../../../assets/img/trash.png";
import ContactIcon from "../../../assets/img/userMenu.png";
import { alertType, shapeMenuEnum } from '../../../beans/enumerators';
import CustomAlert from '../../../components/Alert';
import Icon from '../../../components/Icon';
import useLoadingContext from '../../../utils/hooks/useLoading';
import { ShapeAttribute } from '../interfaces';
import { deleteShape, getShapeAtributes } from '../services';

interface ShapeSideBarProps {
    selectedShape: number
    setSelectedShape: Dispatch<SetStateAction<number | undefined>>
    setValue: (value: string) => void
    getShapes: () => void
}

const ShapeSideBar = ({ selectedShape, setSelectedShape, setValue, getShapes }: ShapeSideBarProps) => {
    const [shapeMenu, setShapeMenu] = useState<string>(shapeMenuEnum.info)
    const [deleteIcon, setDeleteIcon] = useState<string>(ThreshIcon)
    const [openAlert, setOpenAlert] = useState<boolean>(false)
    const [shapeAttributes, setShapeAttributes] = useState<Array<ShapeAttribute>>([])

    const { setIsLoading } = useLoadingContext();
    const sidebarRef = useRef<HTMLDivElement>(null);
    const map = useMap()

    useEffect(() => {
        setIsLoading(true)
        setShapeMenu(shapeMenuEnum.info)
        setShapeAttributes([])
        getShapeAtributes(selectedShape).then((response) => {
            setShapeAttributes(response)
        }).catch(() => {
            setIsLoading(false)
        })
        setIsLoading(false)
    }, [selectedShape])


    function handleClose() {
        setOpenAlert(false)
        setSelectedShape(undefined)
        setValue('')
        setShapeAttributes([])
        map.scrollWheelZoom.enable();
        map.doubleClickZoom.enable();
        map.dragging.enable()

    }

    async function handleDelete() {
        setIsLoading(true)
        await deleteShape(selectedShape).then(() => toast.success(alertType.deleted)).catch(() => {
            setIsLoading(false)
        })
        getShapes()
        handleClose()
        setIsLoading(false)
    }

    useEffect(() => {
        const sidebarElement = sidebarRef.current;

        const disableZoom = () => {
            map.scrollWheelZoom.disable();
            map.doubleClickZoom.disable();
            map.dragging.disable()
        };

        const enableZoom = () => {
            map.scrollWheelZoom.enable();
            map.doubleClickZoom.enable();
            map.dragging.enable();
        };

        sidebarElement?.addEventListener('mouseenter', disableZoom);
        sidebarElement?.addEventListener('mouseleave', enableZoom);

        return () => {
            sidebarElement?.removeEventListener('mouseenter', disableZoom);
            sidebarElement?.removeEventListener('mouseleave', enableZoom);
        };
    }, [map]);



    return (
        <>
            <React.Fragment>
                <Col xs={12} md={4} ref={sidebarRef} className="shapeSideBar">
                    <h2 className='title px-4'>Propriedade
                        <div className='titleButtons'>
                            <Button
                                onClick={() => setOpenAlert(true)}
                                onMouseEnter={() => setDeleteIcon(OpenedThreshIcon)}
                                onMouseLeave={() => setDeleteIcon(ThreshIcon)}>
                                <Icon icon={deleteIcon} size='22px' className='close-icon' />
                            </Button>
                            <Button onClick={handleClose}>
                                <Icon icon={CloseIcon} size='16px' className='close-icon' />
                            </Button>
                        </div>
                    </h2>
                    <Row className="layerHeader">
                        <Col className={`col-auto ${shapeMenu === shapeMenuEnum.info && 'selected'}`} onClick={() => { setShapeMenu(shapeMenuEnum.info) }} key="info">
                            <Button >
                                <Icon icon={InfoIcon} size='20px' />
                            </Button>
                        </Col>
                        <Col className={`col-auto ${shapeMenu === shapeMenuEnum.contact && 'selected'}`} onClick={() => { setShapeMenu(shapeMenuEnum.contact) }} key="contact">
                            <Button >
                                <Icon icon={ContactIcon} size='20px' />
                            </Button>
                        </Col>
                        {/* <Col
                            className={`col-auto ${shapeMenu === shapeMenuEnum.star && 'selected'}`}
                            onClick={() => { setShapeMenu(shapeMenuEnum.star) }}>
                            <Button>
                                <Icon icon={StarIcon} size='20px' />
                            </Button>
                        </Col>
                        <Col
                            className={`col-auto ${shapeMenu === shapeMenuEnum.bars && 'selected'}`}
                            onClick={() => { setShapeMenu(shapeMenuEnum.bars) }}>
                            <Button>
                                <Icon icon={BarsIcon} size='20px' />
                            </Button>
                        </Col> */}
                        <Col className={`col-auto ${shapeMenu === shapeMenuEnum.export && 'selected'}`} onClick={() => { setShapeMenu(shapeMenuEnum.export) }} key="export">
                            <Button >
                                <Icon icon={ExportIcon} size='20px' />
                            </Button>
                        </Col>
                    </Row>

                    {shapeMenu === shapeMenuEnum.info &&
                        <>
                            <Row className="p-2 px-4" key="asdas">
                                <Col lg={12} className="mt-2" key="fdasfdsfdsfds">
                                    <span className='infoTitle'>Informações da Propriedade</span>
                                </Col>
                            </Row>
                            <Row className="px-4 mt-3">
                                {shapeAttributes && shapeAttributes.map((item, key) => {
                                    return (
                                        <>
                                            <Col lg={12} key={key}>
                                                <span className='infoTitle'>{(item.attribute_name!).charAt(0).toUpperCase() + item.attribute_name!.slice(1)}</span>
                                                <p className='infoDescription'>{item.value || ""}</p>
                                            </Col>
                                        </>
                                    )
                                })}
                            </Row>
                        </>}
                    {shapeMenu === shapeMenuEnum.contact &&
                        <>
                            <Row className="p-2 px-4">
                                <Col lg={12} className="mt-2">
                                    <span className='infoTitle'>Informações do Proprietario</span>
                                </Col>
                            </Row>
                        </>
                    }
                    {shapeMenu === shapeMenuEnum.export &&
                        <>
                            <Row className="p-2 px-4">
                                <Col lg={12} className="mt-2">
                                    <span className='infoTitle'>Submeter arquivos</span>
                                </Col>
                            </Row>
                        </>
                    }
                </Col>
            </React.Fragment >

            <CustomAlert
                message={alertType.delete + 'essa shape?'}
                show={openAlert}
                onYes={handleDelete}
                onNo={handleClose}
                onHide={handleClose}
            />
        </>
    );
};

export default ShapeSideBar;
