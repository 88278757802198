import React from 'react';
import ReactApexChart from 'react-apexcharts';

interface GroupedBarChartProps {
    colors: Array<string>;
}

const GroupedBarChart = ({ colors }: GroupedBarChartProps) => {

    const series = [{
        name: 'Product 1',
        data: [44, 55, 41, 67, 22, 43]
    },
    {
        name: 'Product 2',
        data: [13, 23, 20, 8, 13, 27]
    },
    {
        name: 'Product 3',
        data: [11, 17, 15, 15, 21, 14]
    }];

    const options: any = {
        chart: {
            height: 231,
            stacked: true,
            toolbar: {
                show: true
            },
            zoom: {
                enabled: true
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0
                }
            }
        }],
        plotOptions: {
            bar: {
                dataLabels: {
                    position: 'bottom',
                }
            },
        },
        xaxis: {
            type: 'text',
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun'],
        },
        legend: {
            position: 'bottom',
            fontSize: '10px',
            itemMargin: {
                horizontal: 10,
                vertical: 5
            },
            markers: {
                height: 8,
                width: 13,
                radius: 0,
            }
        },
        colors: colors,
        fill: {
            colors: colors,
            opacity: 1,
        },
    };

    return (
        <div id="chart">
            <ReactApexChart options={options} series={series} type="bar" />
        </div>
    );
};

export default GroupedBarChart;
