import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import NewPassword from "./components/NewPassword";
import Logo from "../../components/Logo";

const NewPasswordPage = () => (
  <React.Fragment>
    <Helmet title="Nova senha" />

    <div className="block-max-width mx-auto">
      <Logo />
      <div className="text-center mt-5">
        <h2>Nova senha</h2>
        <p className="lead">Olá, informe sua nova senha</p>
      </div>
      <NewPassword />
      <small className="mt-4 d-block text-center">
        <Link to="/acesso/entrar/">Voltar</Link>
      </small>
    </div>
  </React.Fragment>
);

export default NewPasswordPage;
