import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Icon from '../../../components/Icon';
import CloseIcon from "../../../assets/img/close.png";
import useSideBarContext from '../../../utils/hooks/useSideBarMap';

interface MapSideBarProps {
    title: string
    children?: any
}

const MapSideBar = ({ title, children }: MapSideBarProps) => {
    const { setValue } = useSideBarContext();
    function handleClose() {
        setValue('')
    }
    return (
        <Col xs={12} md={4} className="mapSidebar">
            <h2 className='title px-4'>
                {title}
                <Button onClick={handleClose}>
                    <Icon icon={CloseIcon} size='16px' className='close-icon' />
                </Button>
            </h2>
            <div className='mapSidebar-body'>
                {children}
            </div>
        </Col>
    );
};

export default MapSideBar;
