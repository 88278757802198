import React from "react";
import { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";

import Content from "../components/Content";
import Footer from "../components/Footer";
import Main from "../components/Main";
import Navbar from "../components/navbar/NavbarDashboard";
import adminItems from "../components/sidebar/adminItems";
import generalItems from "../components/sidebar/menuItems";
import Sidebar from "../components/sidebar/Sidebar";
import Wrapper from "../components/Wrapper";
import useToken from "../utils/hooks/useToken";


interface DashboardProps {
  title?: string,
  withFilter: boolean
}

const Dashboard: React.FC<PropsWithChildren & DashboardProps> = ({ children, title, withFilter }) => {
  const { isAdmin } = useToken();

  return (
    <React.Fragment>
      <Wrapper>
        <Sidebar
          items={isAdmin ? adminItems : generalItems}
          showFooter={!isAdmin}
        />
        <Main>
          <Navbar
            title={title}
            withFilter={withFilter}
          />
          <Content>
            {children}
            <Outlet />
          </Content>
        </Main>
      </Wrapper>
    </React.Fragment>
  );
};

export default Dashboard;
