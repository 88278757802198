import { Formik } from "formik";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import * as Yup from "yup";

import PageTitle from "../../../../components/PageTitle";
import MultiSelect from "../../../../components/SelectSearch";
import useLoadingContext from "../../../../utils/hooks/useLoading";
import { ListResponse } from "../../../../utils/interfaces/response";
import { OccupationAreaInterface } from "../../../map/OccupationArea/interfaces";
import { getOccupationAreaToSelect } from "../../../map/OccupationArea/services";
import UserInterface from "../interfaces/user";

interface ModalLayerComponentProps {
    openModal: boolean
    setOpenModal: Dispatch<SetStateAction<boolean>>
    screenUpdate: () => void
    setSelectedItem: Dispatch<any>
    selectedItem?: any
}

const ModalLayerComponent: React.FC<ModalLayerComponentProps> = ({ openModal, setOpenModal, selectedItem, setSelectedItem, screenUpdate }) => {

    const { setIsLoading } = useLoadingContext();
    const [initialValues, setInitialValues] = useState<UserInterface>({
        name: '',
        email: '',
        user: '',
        password: '',
    })
    const [occupationAreaList, setOccupationAreaList] = useState<Array<ListResponse>>([])
    const [selectedOccupationAreas, setSelectedOccupationAreas] = useState<Array<OccupationAreaInterface>>([])

    useEffect(() => {
        getOccupationAreaToSelect().then(setOccupationAreaList)
    }, [])

    function handleCreate(values: UserInterface) {
        setIsLoading(true);
        const usuarios = JSON.parse(localStorage.getItem('usuarios') || '[]');
        const newUser = { id: usuarios.length, ...values };
        const updatedUsers = [...usuarios, newUser];
        localStorage.setItem('usuarios', JSON.stringify(updatedUsers));
        setIsLoading(false);
        screenUpdate();
        handleCloseModal();
    }


    function handleEdit(values: UserInterface) {
        setIsLoading(true);
        const usuarios = JSON.parse(localStorage.getItem('usuarios') || '[]');
        const updatedUsers = usuarios.map((user: UserInterface) => {
            if (user.id === selectedItem) {
                return { ...user, ...values };
            }
            return user;
        });
        localStorage.setItem('usuarios', JSON.stringify(updatedUsers));
        setIsLoading(false);
        screenUpdate();
        handleCloseModal();
    }

    useEffect(() => {
        if (selectedItem) {
            const usuarios = JSON.parse(localStorage.getItem('usuarios') || '[]');
            const selectedUser = usuarios.find((user: UserInterface) => user.id === selectedItem);
            if (selectedUser) {
                setInitialValues(selectedUser);
            } else {
                setInitialValues({
                    name: '',
                    email: '',
                    user: '',
                    password: '',
                });
                setSelectedOccupationAreas([])//mudar para pegar as areas de atuação vindas da rota 
            }
        } else {
            setInitialValues({
                name: '',
                email: '',
                user: '',
                password: '',
            });
            setSelectedOccupationAreas([])
        }
    }, [selectedItem])


    function handleCloseModal() {
        setOpenModal(false)
        setSelectedItem('')
        setSelectedOccupationAreas([])
    }

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={
                    Yup.object().shape({
                        name: Yup.string(),
                        email: Yup.string(),
                        user: Yup.string(),
                        password: Yup.string()
                    })
                }
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    if (selectedItem) {
                        handleEdit(values)
                    } else {
                        handleCreate(values)
                    }
                    resetForm({ values: { ...initialValues } });

                }}>{({ handleBlur, handleChange, handleSubmit, isSubmitting, values, errors }) => (
                    < Modal dialogClassName="ts-modal" show={openModal} onHide={handleCloseModal} >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <PageTitle text={`${!!selectedItem ? 'Editar' : 'Adicionar'} Usuário`} />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body  >
                            <Form onSubmit={handleSubmit} className="px-2" >
                                <Form.Group className="mb-3">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control
                                        size="lg"
                                        required
                                        name="name"
                                        className="inputBorderBottom"
                                        value={values.name}
                                        onChange={handleChange}
                                        placeholder="Nome"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Usuário</Form.Label>
                                    <Form.Control
                                        size="lg"
                                        required
                                        name="user"
                                        className="inputBorderBottom"
                                        value={values.user}
                                        onChange={handleChange}
                                        placeholder="Usuário"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        size="lg"
                                        required
                                        type="email"
                                        name="email"
                                        className="inputBorderBottom"
                                        value={values.email}
                                        onChange={handleChange}
                                        placeholder="Email"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Senha</Form.Label>
                                    <Form.Control
                                        size="lg"
                                        required
                                        type="password"
                                        name="password"
                                        className="inputBorderBottom"
                                        value={values.password}
                                        onChange={handleChange}
                                        placeholder="Senha"
                                    />
                                </Form.Group>
                                <MultiSelect
                                    options={occupationAreaList}
                                    placeholder="Selecione as áreas de atuação"
                                    labelText={"Selecione as áreas de atuação"}
                                    selectedOptions={selectedOccupationAreas}
                                    setSelectedOptions={setSelectedOccupationAreas}
                                    withList
                                />
                                <Row className="modal-newlayer-footer">
                                    <hr />
                                    <Col className="d-flex justify-content-end">
                                        <Button className="redbg ts-link-icon mx-2" onClick={handleCloseModal}> CANCELAR</Button>
                                        <Button
                                            type="submit"
                                            className="primary-btn modalSubmi-btn"
                                            disabled={isSubmitting}
                                        >
                                            {selectedItem ? 'EDITAR' : 'CRIAR'}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal.Body>
                    </Modal >
                )}
            </Formik>
        </>

    )
}

export default ModalLayerComponent;