import { Card, Col, Row } from "react-bootstrap";

import { LineKey } from "../../../../beans/enumerators";
import Icon from "../../../../components/Icon";
import Avatar from "../../../../components/lists/Avatar";
import { Info } from "../interfaces";

interface FourthLineProps {

    title?: string
    subTitle?: string
    info?: Array<Info>
    type?: LineKey | null | undefined | number
}


export function FourthLine({ title, subTitle, info, type }: FourthLineProps) {

    const { location, previsionType, temperature, max, min, previsionIcon, timeInfo, alert } = info![0]

    return (
        <>
            {type !== LineKey.TimePrevision ?
                <Col lg={4} key={title}>
                    <Card className="forthLine" >
                        <p className="cardTitle">{title}</p>
                        <p className="cardSubtitle">{subTitle}</p>
                        <Row>

                            {!!info?.length && info.map((item, key) => {
                                return (
                                    <div className="infoMaquinary" key={key}>
                                        <div className="itemInfo">
                                            <Avatar src={item.img} />
                                            <span className="px-3 text-left">{item.name}</span>
                                        </div>
                                        <span className="itemInfo">{item.porcent}%</span>
                                    </div>)
                            })}
                        </Row>
                    </Card>
                </Col>
                :
                <Col lg={4} >
                    <Card className="temperatureCard">
                        <Row>
                            <Col lg={12} className="titleRow" >
                                <span className="cardTitle">{location}</span>
                                <span className="cardSubtitle">{previsionType}</span>
                            </Col>
                        </Row>
                        <Row className="temperature">
                            <Col xs={3} sm={3} md={2} lg={6} xl={4} xxl={3} className="numeral bg">{temperature}</Col>
                            <Col xs={5} sm={3} md={2} lg={6} xl={4} xxl={4} className="pt-1">
                                <Row>
                                    <Col lg={12} className="bg mx-2 p-2">
                                        <span className="maxMinTemperature">{`Max: ${max}`}</span>
                                    </Col>
                                    <Col lg={12} className="bg mx-2 p-2 mt-1">
                                        <span className="maxMinTemperature">{`Min: ${min}`}</span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={4} sm={6} md={8} lg={8} xl={4} xxl={5} className="tempIcon pt-lg-2">
                                <Icon icon={previsionIcon!} size="5rem" />
                            </Col>
                        </Row>

                        <Row className="climaticBoxes mt-2 mx-xl-0 d-xl-flex ">
                            {!!timeInfo?.length && timeInfo.map((info, key) => {
                                return (
                                    //  
                                    <Col xs={3} sm={3} md={2} lg={3} xl={3} xxl={2} className="bg box mx-1 " key={key}>
                                        <Icon icon={info.icon} size="18px" />
                                        <div className="pt-2">{info.additionalInfo ? info.additionalInfo : <br />}</div>
                                        <span>{info.valueInfo}</span>
                                    </Col>)
                            })}
                        </Row>
                        <p className="cardTitle mt-4">Alertas metereológicos</p>
                        <Col lg={12} className="bg alert">
                            <Icon icon={alert?.icon!} size="20px" className="mx-2" />
                            <span>{alert?.description}</span>
                        </Col>
                    </Card>
                </Col>
            }

        </>
    )
}