/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

import { Badge, Collapse } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import Icon from "../Icon";
import useSideBarContext from "../../utils/hooks/useSideBarMap";

interface SidebarNavListItemProps {
  className?: string;
  depth: number;
  href: string;
  icon: IconDefinition | string;
  badge?: string;
  open?: boolean;
  title: string;
  children?: React.ReactNode;
  mapSideBar?: string
}

const SidebarNavListItem = (props: SidebarNavListItemProps) => {
  const {
    title,
    href,
    depth = 0,
    children,
    icon,
    badge,
    mapSideBar,
    open: openProp = false,
  } = props;

  const [open, setOpen] = React.useState(openProp);
  const { setValue } = useSideBarContext();

  function handleSideBar(value: any) {
    setValue(value);
  }

  const handleToggle = () => {
    setOpen((state) => !state);
  };

  if (children) {
    return (
      <li className={`sidebar-item ${open ? "active" : ""}`} onClick={() => { handleSideBar(mapSideBar) }}>
        <a
          className={`sidebar-link ${open ? "" : "collapsed"}`}
          data-bs-toggle="collapse"
          aria-expanded={open ? "true" : "false"}
          data-depth={depth}
          onClick={handleToggle}
        >
          {typeof icon === 'string' ? <Icon icon={icon} size={'20px'} />
            : <FontAwesomeIcon icon={icon} className="align-middle" />}
          <span className="align-middle" data-depth={depth}>
            {title}
          </span>
          {badge && (
            <Badge className="badge-sidebar-primary" bg="">
              {badge}
            </Badge>
          )}
          {open ? <div /> : <div />}
        </a>
        <Collapse in={open}>
          <ul className="sidebar-dropdown list-unstyled">{children}</ul>
        </Collapse>
      </li>
    );
  }

  return (
    <li className={`sidebar-item`} onClick={() => { handleSideBar(mapSideBar) }}>
      <NavLink data-depth={depth} to={href} className="sidebar-link d-flex flex-column align-items-center">
        {typeof icon === 'string' ? <Icon icon={icon} size={'20px'} /> : <FontAwesomeIcon icon={icon} className="align-middle m-1" />}
        <span className="align-justify" data-depth={depth}>
          {title}
        </span>
        {badge && (
          <Badge className="badge-sidebar-primary" bg="">
            {badge}
          </Badge>
        )}
      </NavLink>
    </li>
  );

};

export default SidebarNavListItem;
