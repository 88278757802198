import { Card, Col, Row } from "react-bootstrap";
import ArrowDown from "../../../../assets/img/arrow-down.png";
import ArrowUp from "../../../../assets/img/arrow-up.png";
import Icon from "../../../../components/Icon";

interface ThirdLineProps {
    name?: string
    value?: string
    flag?: boolean
}


export function ThirdLine({ name, value, flag }: ThirdLineProps) {
    return (
        <>
            <Col>
                <span>{name}</span>
                <span className="item-value mx-2">{value}
                    <Icon icon={flag ? ArrowUp : ArrowDown} size='10px' className="mx-1 my-1" />
                </span>
            </Col>
        </>
    )
}