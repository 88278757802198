import React, { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import AddIcon from "../../../assets/img/add.png";
import OptionsIcon from "../../../assets/img/options.png";
import { alertType } from '../../../beans/enumerators';
import CustomAlert from '../../../components/Alert';
import Icon from '../../../components/Icon';
import MapSideBar from '../components/MapSideBar';
import ModalOccupationAreaComponent from './components/ModalOccupationAreaComponent';
import { OccupationAreaInterface } from './interfaces';
import { deleteOccupationArea, getOccupationAreaList } from './services';
import useLoadingContext from '../../../utils/hooks/useLoading';

const OccupationAreaSidebar = () => {

    const { setIsLoading } = useLoadingContext();
    const [openModal, setOpenModal] = useState(false)
    const [openAlert, setOpenAlert] = useState<boolean>(false)
    const [occupationAreaList, setOccupationAreaList] = useState<Array<OccupationAreaInterface>>([])
    const [selectedItem, setSelectedItem] = useState<number>()

    useEffect(() => {
        screenUpdate()
    }, [])

    async function screenUpdate() {
        await getOccupationAreaList().then(setOccupationAreaList).catch(() => {
            setIsLoading(false)
        })
    }

    async function handleDelete() {
        if (selectedItem) {
            await deleteOccupationArea(selectedItem).then(() => {
                toast.success(alertType.deleted)
            }).catch(() => {
                setIsLoading(false)
            })
            handleCloseAlert()
            screenUpdate();
        }
    }

    function handleCloseAlert() {
        setSelectedItem(0)
        setOpenAlert(false)
    }

    return (
        <MapSideBar title={"Área de Atuação"}>
            <>
                <React.Fragment>
                    <Row className="defaultSidebar mx-2">
                        <Col lg={12} className="default-header">
                            <h4 className='default-title '>Suas áreas de atuação</h4>
                            <Button onClick={() => setOpenModal(true)}>
                                <Icon icon={AddIcon} size='12px' />
                                <span className='px-2'>NOVA ÁREA DE ATUAÇÃO</span>
                            </Button>
                        </Col>
                    </Row>
                    <Row className='defaultSidebar mx-2'>
                        {!!occupationAreaList && occupationAreaList.map((item, key) => {
                            return (
                                <>
                                    <Col lg={12} className='occupationAreaSidebar-body' key={key}>
                                        <span>{item.name}</span>
                                        <Dropdown className="nav-item no-bg">
                                            <span>
                                                <Dropdown.Toggle className={'nav-link'}>
                                                    <Icon icon={OptionsIcon} size='22px' className='close-icon' />
                                                </Dropdown.Toggle>
                                            </span>
                                            <Dropdown.Menu className=''>
                                                {/* <Dropdown.Item onClick={() => { console.log('ocultar') }}>
                                                    Ocultar no mapa
                                                </Dropdown.Item> */}
                                                <Dropdown.Item onClick={() => { setSelectedItem(item.id); setOpenModal(true) }}>
                                                    Editar área de atuação
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => { setSelectedItem(item.id); setOpenAlert(true) }}>
                                                    Deletar área de atuação
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                </>
                            )
                        })}
                    </Row>
                </React.Fragment>

                <CustomAlert
                    message={alertType.delete + 'essa área de atuação?'}
                    show={openAlert}
                    onYes={handleDelete}
                    onNo={handleCloseAlert}
                    onHide={handleCloseAlert}
                />

                {!!openModal &&
                    <ModalOccupationAreaComponent
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                        screenUpdate={screenUpdate}
                    />}
            </>
        </MapSideBar >

    );
};

export default OccupationAreaSidebar;
