import { Formik } from "formik";
import React, { useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import * as Yup from "yup";

import { statusCode } from "../../../beans/enumerators";
import { messagesForm } from "../../../beans/messages";
import { ResetPasswordForm } from "../interfaces/auth";
import { resetPasswordApi } from "../services/auth";
import { Link } from "react-router-dom";

const ResetPassword = () => {
  const [sent, setSent] = useState(false);
  const initialValues: ResetPasswordForm = { submit: false };

  return sent ? (
    <Alert variant="success">
      <div className="alert-message">
        Enviado com sucesso, confira seu e-mail!
      </div>
    </Alert>
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(messagesForm.invalid)
          .max(100)
          .required(messagesForm.required),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        if (values.email) {
          resetPasswordApi(values.email).then((response) => {
            if (response.status !== statusCode.ok) {
              const message: string = response.statusText || messagesForm.error;
              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            } else {
              setSent(true);
            }
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              size="lg"
              type="email"
              name="email"
              value={values.email}
              isInvalid={Boolean(touched.email && errors.email)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.email && (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          {errors.submit && (
            <Alert className="mt-4" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}
          <small className="mt-4 d-block ">
            <Link to="/acesso/entrar/">Acessar o sistema</Link>
          </small>

          <div className="mt-4 text-center">
            <Button
              type="submit"
              variant="terciary"
              size="lg"
              disabled={isSubmitting}
            >
              RECEBER CÓDIGO
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPassword;
