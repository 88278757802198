import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import PageTitle from '../../../components/PageTitle';

import * as turf from '@turf/turf';
import { useMap } from 'react-leaflet';
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { DefaultGeometryType, GeometryTypeShortedEnum, alertType, statusCode } from '../../../beans/enumerators';
import CustomAlert from '../../../components/Alert';
import { checkGeometry } from '../../../utils/checkGeometry';
import useLoadingContext from '../../../utils/hooks/useLoading';
import { ListResponse } from '../../../utils/interfaces/response';
import { getLayersList } from '../../Dashboard/Layer/services/layers';
import { ShapeAttribute } from '../interfaces';
import { createShape } from '../services';
import { ShapeData } from './MapDrawTools';

interface ModalCreateShapeProps {
    openModal: boolean
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
    shapeCoordinate: ShapeData
    shapeType: string
    getShapes: any
    idDrawShape: any
}

const ModalCreateShape: React.FC<ModalCreateShapeProps> = ({ openModal, setOpenModal, shapeCoordinate, shapeType, getShapes, idDrawShape }) => {
    const { setIsLoading } = useLoadingContext();
    const [layerSelectList, setLayerSelectList] = useState<Array<ListResponse>>([])
    const [openAlert, setOpenAlert] = useState<boolean>(false)
    const map = useMap();
    const initialValues = {
        layerId: 0,
        geometryType: '',
    }
    useEffect(() => {
        let arraySelectedLayer = JSON.parse(String(localStorage.getItem('checkedLayer')))
        if (!!arraySelectedLayer && arraySelectedLayer.length) {
            getLayersList(arraySelectedLayer).then((response) => {
                setLayerSelectList(response)
            }).catch(() => {
                setIsLoading(false)
            })
        } else {
            setOpenAlert(true)
        }
    }, [])


    function handleCloseModal() {
        map.removeLayer(idDrawShape)
        getShapes()
        setOpenModal(false);
    }

    async function handleCreate(values: any) {
        let data
        let layer = layerSelectList.find((item) => {
            if (item.value == values.layerId) return item
        })

        if (layer?.type && (checkGeometry(layer?.type as GeometryTypeShortedEnum) !== shapeType)) {
            toast.error(alertType.shapeNotSupported)
        } else {
            var { coordinates } = shapeCoordinate
            if (shapeType === DefaultGeometryType.point) {
                data = {
                    point: {
                        point_type: "U"
                    },
                    geoJson: {
                        type: 'Point',
                        coordinates: [coordinates.lng, coordinates.lat]
                    },
                    layer: {
                        id: values.layerId
                    }
                }
            } else {
                var cords
                var attributes: ShapeAttribute[] = []
                if (shapeType === DefaultGeometryType.line) {
                    cords = coordinates.map((cord: any) => {
                        return [cord.lng, cord.lat]
                    })
                    const line = turf.lineString(cords);
                    attributes.push({ attribute_name: "distância", value: turf.length(line, { units: 'kilometers' }).toFixed(2) }) // Calcula o comprimento da linha em metros
                }

                if (shapeType === DefaultGeometryType.polygon) {
                    cords = [coordinates.map((cord: any) => {
                        return [cord.lng, cord.lat]
                    })]

                    const polygon = turf.polygon(cords);
                    attributes.push({ attribute_name: "área", value: (turf.area(polygon) / 10000).toFixed(2) }) //Converte em hectares
                }

                var type = shapeType === DefaultGeometryType.line ? 'LineString' : shapeType
                data = {
                    geoJson: {
                        type: type,
                        coordinates: cords
                    },
                    layer: {
                        id: values.layerId
                    },
                    attributes: attributes
                }
            }
            setIsLoading(true);
            map.removeLayer(idDrawShape)
            await createShape(data).then(() => {
                toast.success(alertType.created)
                handleCloseModal()
            }).catch(() => {
                setIsLoading(false)
            })
            handleCloseModal()
            setIsLoading(false);
        }
    }
    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={
                    Yup.object().shape({
                        layerId: Yup.number(),
                        geometryType: Yup.string()

                    })
                }
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);
                    handleCreate(values)

                }}>{({ handleBlur, handleChange, handleSubmit, isSubmitting, values }) => (
                    <Modal dialogClassName="ts-modal" show={openModal && !openAlert} onHide={handleCloseModal} >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <PageTitle text={'Criar Ponto'} />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body  >
                            <Form onSubmit={handleSubmit} className="px-2" >
                                {shapeType === DefaultGeometryType.point &&
                                    <><Form.Group className="mb-3">
                                        <Form.Label>Latitude</Form.Label>
                                        <Form.Control
                                            size="lg"
                                            className="inputBorderBottom"
                                            value={shapeCoordinate.coordinates.lat}
                                            disabled
                                        />
                                    </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Longitude</Form.Label>
                                            <Form.Control
                                                size="lg"
                                                className="inputBorderBottom"
                                                value={shapeCoordinate.coordinates.lng}
                                                disabled
                                            />
                                        </Form.Group>
                                    </>}

                                <Form.Group className="mb-3 text-start ">
                                    <Form.Label>Camada</Form.Label>
                                    <Form.Select
                                        required
                                        name="layerId"
                                        className="inputBorderBottom"
                                        value={values.layerId}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    >
                                        <option key="" value="">Selecione</option>
                                        {layerSelectList.map((item, key) => {
                                            return (<option key={key} value={Number(item.value)}>{item.label}</option>)
                                        })}
                                    </Form.Select>
                                </Form.Group>


                                <Row className="modal-newlayer-footer">
                                    <hr />
                                    <Col className="d-flex justify-content-end">
                                        <Button className="redbg ts-link-icon mx-2" onClick={handleCloseModal}> CANCELAR</Button>
                                        <Button
                                            type="submit"
                                            className="primary-btn modalSubmi-btn"
                                            disabled={isSubmitting}
                                        >
                                            CRIAR CAMADA
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal.Body>
                    </Modal >
                )}

            </Formik>
            <CustomAlert
                message={alertType.noLayerSelected}
                show={openAlert}
                onCancel={() => { setOpenAlert(false); setOpenModal(false); map.removeLayer(idDrawShape) }}
                onHide={() => { console.log('oi'); setOpenAlert(false); setOpenModal(false); map.removeLayer(idDrawShape) }}
            />
        </>
    );
}

export default ModalCreateShape;
