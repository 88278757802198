// Guards
import GuestGuard from "./components/guards/GuestGuard";
// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import DefaultLayout from "./layouts/Default";
import MapLayout from "./layouts/Map";
// Pages
import NewPassword from "./pages/auth/NewPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import SignIn from "./pages/auth/SignIn";
import Layer from "./pages/Dashboard/Layer";
import Polling from "./pages/Dashboard/Polling";
import Dashboard from "./pages/Dashboard/statistic";
import User from "./pages/Dashboard/User";
import UserGroupPage from "./pages/Dashboard/UserGroup";
import Default from "./pages/Default/Default";
import Page404 from "./pages/Error/Page404";
import Page500 from "./pages/Error/Page500";
import Map from "./pages/map";
// Context Providers
import { FilterProvider } from "./utils/contexts/FilterContext";
import { SideBarMapProvider } from "./utils/contexts/SideBarMapContext";

const routes = [
  {
    path: "/",
    element: (
      // <AuthGuard>
      <GuestGuard>
        <SideBarMapProvider>
          <MapLayout />
        </SideBarMapProvider>
      </GuestGuard>
      // </AuthGuard>
    ),
    children: [
      { path: "mapa", element: <Map /> },
      { path: "area-de-atuacao", element: <Map /> },
      { path: "relatorios", element: <Default /> },
    ],
  },
  {
    path: "/",
    element: (
      <GuestGuard>
        <FilterProvider>
          <DashboardLayout title="Painel de Controle" withFilter />
        </FilterProvider>
      </GuestGuard>
    ),
    children: [
      { path: "estatisticas", element: <Dashboard /> },
      { path: "camadas", element: <Layer /> },
      { path: "usuarios", element: <User /> },
      { path: "grupousuarios", element: <UserGroupPage /> },
      { path: "polling", element: <Polling /> },
    ],
  },
  {
    path: "/inConstruction",
    element: (
      <DefaultLayout>
        <Default />
      </DefaultLayout>
    ),
  },
  {
    path: "acesso",
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      { path: "entrar", element: <SignIn /> },
      { path: "recuperar-senha", element: <ResetPassword /> },
      { path: "nova-senha/:hash", element: <NewPassword /> },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      { path: "404", element: <Page404 /> },
      { path: "500", element: <Page500 /> },
      { path: "*", element: <Page404 /> },
    ],
  },
];

export default routes;
