import { Card, Col, Row } from "react-bootstrap";
import CircledArrowDown from "../../../../assets/img/circled-arrow-down.png";
import CircledArrowUp from "../../../../assets/img/circled-arrow-up.png";
import Icon from "../../../../components/Icon";

interface FirstLineProps {
    name?: string
    value?: string
    description?: string
    flag?: boolean
}

export function FirstLine({ description, flag, name, value }: FirstLineProps) {
    return (
        <Col lg={3} className="mb-4">
            <Card className="firstLineCards">
                <Row className="numeral-card ">
                    <Icon icon={flag ? CircledArrowUp : CircledArrowDown} width="50px" height="25px" />
                    <span className="titles">{value}</span>
                    <p className="subtitle">{name}</p>
                    <p className="text">{description}</p>
                </Row>
            </Card>
        </Col>
    )
}