import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

interface Option {
    text: string;
    value: number;
}

interface SelectComponentProps {
    defaultIndex?: number;
    options?: Option[];
    onChange: (selectedValue: string | number) => void;
    className?: string
}

const SelectComponent: React.FC<SelectComponentProps> = ({ defaultIndex = 0, options = [], onChange, className }) => {
    const [selectedValue, setSelectedValue] = useState(0);

    const handleChange = (event: any) => {
        const value = event.target.value;
        setSelectedValue(value);
        onChange(value)
    };

    useEffect(() => {
        if (options.length > 0) {
            setSelectedValue(options[defaultIndex].value);
            onChange(options[defaultIndex].value);
        }
    }, []);

    return (
        <Form.Group > 
            <Form.Control
                as="select"
                value={selectedValue}
                onChange={handleChange}
                className={className}
            >
                {options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.text}
                    </option>
                ))}
            </Form.Control>
        </Form.Group>
    );
};

export default SelectComponent;
