import jwtDecode from "jwt-decode";
import React, { useState } from "react";

import { accountType } from "../../beans/enumerators";
import { TokenState, TokenUser } from "../interfaces/token";
import { getToken, isValidToken } from "../token";

const initialValue = getToken();
const decodeUser = initialValue ? jwtDecode<TokenUser>(initialValue) : null;

const initialState: TokenState = {
  isValid: isValidToken(initialValue),
  setIsValid: (valid: boolean) => { },
  value: initialValue,
  setValue: (value: string | null) => { },
  user: decodeUser,
  setUser: (user: TokenUser | null) => { },
  isAdmin: decodeUser?.role === accountType.MASTER,
  setIsAdmin: (admin: boolean) => { },
};

const TokenContext = React.createContext(initialState);

interface TokenProviderType {
  children: React.ReactNode;
}

function TokenProvider({ children }: TokenProviderType) {
  const [isValid, setIsValid] = useState(initialState.isValid);
  const [value, setValue] = useState(initialState.value);
  const [user, setUser] = useState<TokenUser | null>(initialState.user);
  const [isAdmin, setIsAdmin] = useState(initialState.isAdmin);


  return (
    <TokenContext.Provider
      value={{
        isValid,
        setIsValid,
        value,
        setValue,
        user,
        setUser,
        isAdmin,
        setIsAdmin,
      }}
    >
      {children}
    </TokenContext.Provider>
  );
}

export { TokenProvider, TokenContext };
