import { Formik } from "formik";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import * as Yup from "yup";

import { toast } from "react-toastify";
import { alertType } from "../../../../beans/enumerators";
import PageTitle from "../../../../components/PageTitle";
import useLoadingContext from "../../../../utils/hooks/useLoading";
import UserGroupInterface from "../interfaces/userGroup";
import { createUserGroup, getUserGroupByID, updateUserGroup } from "../services";

interface ModalUserGroupComponentProps {
    openModal: boolean
    setOpenModal: Dispatch<SetStateAction<boolean>>
    screenUpdate: () => void
    setSelectedItem: Dispatch<any>
    selectedItem?: any
}

const ModalUserGroupComponent: React.FC<ModalUserGroupComponentProps> = ({ openModal, setOpenModal, selectedItem, setSelectedItem, screenUpdate }) => {

    const { setIsLoading } = useLoadingContext();
    const [initialValues, setInitialValues] = useState<UserGroupInterface>({
        name: undefined
    })

    async function handleCreate(values: UserGroupInterface) {
        setIsLoading(true);
        await createUserGroup(values).then((response) => {
            if (response) {
                toast.success(alertType.created)
            }
        })
        setIsLoading(false);
        screenUpdate();
        handleCloseModal();
    }


    async function handleEdit(values: UserGroupInterface) {
        setIsLoading(true);
        await updateUserGroup(selectedItem, values).then((response) => {
            if (response) {
                toast.success(alertType.updated)
            }
        })
        setIsLoading(false);
        screenUpdate();
        handleCloseModal();
    }

    useEffect(() => {
        if (selectedItem) {
            getUserGroupByID(selectedItem).then((response) => {
                if (response) {
                    setInitialValues(response);
                } else {
                    setInitialValues({ name: '' });
                }
            })
        }
    }, [selectedItem])

    function handleCloseModal() {
        setOpenModal(false)
        setSelectedItem('')
        setInitialValues({});
    }

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={
                    Yup.object().shape({
                        name: Yup.string()
                    })
                }
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    if (selectedItem) {
                        handleEdit(values)
                    } else {
                        handleCreate(values)
                    }
                    resetForm({ values: { ...initialValues } });

                }}>{({ handleBlur, handleChange, handleSubmit, isSubmitting, values, errors }) => (
                    < Modal dialogClassName="ts-modal" show={openModal} onHide={handleCloseModal} >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <PageTitle text={`${!!selectedItem ? 'Editar' : 'Adicionar'} Grupo de Usuário`} />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body  >
                            <Form onSubmit={handleSubmit} className="px-2" >
                                <Form.Group className="mb-3">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control
                                        size="lg"
                                        required
                                        name="name"
                                        className="inputBorderBottom"
                                        value={values.name}
                                        onChange={handleChange}
                                        placeholder="Nome"
                                    />
                                </Form.Group>
                                <Row className="modal-newlayer-footer">
                                    <hr />
                                    <Col className="d-flex justify-content-end">
                                        <Button className="redbg ts-link-icon mx-2" onClick={handleCloseModal}> CANCELAR</Button>
                                        <Button
                                            type="submit"
                                            className="primary-btn modalSubmi-btn"
                                            disabled={isSubmitting}
                                        >
                                            {selectedItem ? 'EDITAR' : 'CRIAR'}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal.Body>
                    </Modal >
                )}
            </Formik>
        </>

    )
}

export default ModalUserGroupComponent;