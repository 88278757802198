import { Helmet, HelmetProvider } from "react-helmet-async";
import { useRoutes } from "react-router-dom";

import { SidebarProvider } from "./components/sidebar/contexts/SidebarContext";
import { TokenProvider } from "./utils/contexts/TokenContext";
import routes from "./routes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PollingVerifierProvider } from "./utils/contexts/PollingVerifierContext";
import { LoadingProvider } from "./utils/contexts/LoadingContext";

const App = () => {
  const content = useRoutes(routes);

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate={"%s | " + process.env.REACT_APP_WEBSITE_NAME}
        defaultTitle={process.env.REACT_APP_WEBSITE_NAME}
      />
      <TokenProvider>
        <LoadingProvider>
          <PollingVerifierProvider>
            <SidebarProvider>
              {content}
            </SidebarProvider>
          </PollingVerifierProvider>
        </LoadingProvider>
      </TokenProvider>
      <ToastContainer />
    </HelmetProvider>
  );
};

export default App;
