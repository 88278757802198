import React, { useState, useEffect } from "react";
import { Pagination } from "react-bootstrap";

interface PaginationProps {
    pagination: number;
    filter: any;
    verifyFilters: Function;
    total: number;
}

const PaginationCustom = ({ pagination, filter, verifyFilters, total }: PaginationProps) => {
    const [paginationNumbers, setPaginationNumbers] = useState<any>();

    function updatePagination(qtd: number) {
        let paginationArrayPag: Array<number> = [];
        for (let i = 0; i < qtd; i++) {
            paginationArrayPag.push(i + 1);
        }
        setPaginationNumbers(() => (
            <React.Fragment>
                {paginationArrayPag.map((item, key) => (
                    <Pagination.Item
                        active={item === Number(filter)}
                        key={key}
                        onClick={() => verifyFilters("pagination", item.toString())}
                    >
                        {item}
                    </Pagination.Item>
                ))}
            </React.Fragment>
        ));
    }

    useEffect(() => {
        updatePagination(pagination);
    }, [filter, pagination]);

    return (
        <div className="d-flex justify-content-between align-items-center align-center ">
            <div>
                <span>{total} registros encontrados</span>
            </div>
            <div className="mt-3">
                {pagination > 1 && (
                    <Pagination>
                        {filter > 1 && (
                            <Pagination.Prev
                                onClick={() => verifyFilters("pagination", (Number(filter) - 1).toString())}
                            />
                        )}
                        {paginationNumbers}
                        {filter < pagination && (
                            <Pagination.Next
                                onClick={() => verifyFilters("pagination", (Number(filter) + 1).toString())}
                            />
                        )}
                    </Pagination>
                )}
            </div>
        </div>
    );
};

export default PaginationCustom;
