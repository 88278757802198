import React from "react";
import { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";

import Content from "../components/Content";
import Footer from "../components/Footer";
import Main from "../components/Main";
import Navbar from "../components/navbar/Navbar";
import adminItems from "../components/sidebar/adminItems";
import generalItems from "../components/sidebar/menuItems";
import Sidebar from "../components/sidebar/Sidebar";
import Wrapper from "../components/Wrapper";
import useToken from "../utils/hooks/useToken";

const MapLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const { isAdmin } = useToken();

  return (
    <React.Fragment>
      <Wrapper>
        <Sidebar
          items={isAdmin ? adminItems : generalItems}
          showFooter={!isAdmin}
        />
        <Main>
          <Content>
            {children}
            <Outlet />
          </Content>
          {/* <Footer /> */}
        </Main>
      </Wrapper>
      {/*!isAdmin && <AttendantModal />*/}
    </React.Fragment>
  );
};

export default MapLayout;
