import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Col, Form, FormControl, Image, InputGroup, Row } from 'react-bootstrap';
import AddIcon from "../../../assets/img/add.png";
import ClosedFolderIcon from "../../../assets/img/closed-folder.jpg";
import OpenFolderIcon from "../../../assets/img/open-folder.jpg";
import WhiteDocumentIcon from "../../../assets/img/white-document.png";
import { layerTypeEnum } from "../../../beans/enumerators";
import Icon from '../../../components/Icon';
import useLoadingContext from "../../../utils/hooks/useLoading";
import ModalLayerComponent from '../../Dashboard/Layer/components/ModalLayerComponent';
import MapSideBar from '../components/MapSideBar';
import LayerDropdown from './components/LayerDropdown';
import { MapLayerInterface } from "./interfaces";
import { getLayersToMap } from "./services";

interface LayerSidebarProps {
    checkedLayer: Array<number>
    setCheckedLayer: Dispatch<SetStateAction<any[]>>
    isLayerDeleted: boolean
    setIsLayerDeleted: Dispatch<SetStateAction<boolean>>
}

const LayerSidebar = ({ checkedLayer, setCheckedLayer, isLayerDeleted, setIsLayerDeleted }: LayerSidebarProps) => {
    const [openModal, setOpenModal] = useState(false)
    const [openSistemLayers, setOpenSistemLayers] = useState(false)
    const [openUserLayers, setOpenUserLayers] = useState(false)

    const [searchValue, setSearchValue] = useState<string>("");
    const [layerType, setLayerType] = useState<string>(layerTypeEnum.all);
    const [selectedItem, setSelectedItem] = useState<any>("")


    const { setIsLoading } = useLoadingContext();
    const [hierarchyLayerList, setHierarchyLayerList] = useState<MapLayerInterface>()
    const [checkedFolder, setCheckedFolder] = useState<Array<any>>([])

    useEffect(() => {
        setOpenUserLayers(false)
        setOpenSistemLayers(false)
        getLayerHierarchy()

        let checkedFolders = JSON.parse(String(localStorage.getItem('checkedFolder')))
        checkedFolders && setCheckedFolder(checkedFolders)

    }, [layerType])


    async function getLayerHierarchy() {
        setIsLoading(true)
        await getLayersToMap({ layerType, searchValue }).then((response) => {
            setHierarchyLayerList(response)
        }).catch(() => {
            setIsLoading(false)
        })
        setIsLoading(false)
    }

    function clearSelectedFolderAndLayer() {
        setCheckedFolder([])
        setCheckedLayer([])
        localStorage.setItem('checkedFolder', JSON.stringify([]))
        localStorage.setItem('checkedLayer', JSON.stringify([]))
    }

    function generateMenu(type: string) {
        var hierarchyArray = new Array<any>();
        if (hierarchyLayerList) {

            var tipedLayerList = type === layerTypeEnum.sistem ? hierarchyLayerList.sistemLayer : hierarchyLayerList.userLayer
            if (!!tipedLayerList.length) {
                hierarchyArray.push(tipedLayerList.map((item, key) => {
                    return (
                        <div key={`item-menu-${type}-${key}`} >
                            <Row className="mx-1 mt-2">
                                <Col className="mb-1 mx-2 mx-lg-0 px-lg-4 cursor-pointer col-auto col-lg-3" onClick={() => setChecked('folder', 0, item)}>
                                    <Image
                                        style={{ float: "left" }}
                                        src={checkedFolder.indexOf(item.id) !== -1 ? OpenFolderIcon : ClosedFolderIcon}
                                        width={20}
                                        fluid />
                                    <Form.Check style={{ fontSize: 12, marginLeft: 10, float: "left" }}>
                                        <Form.Check.Input checked={checkedFolder.indexOf(item.id) !== -1} readOnly />
                                    </Form.Check>
                                </Col>
                                <Col className={`col-auto ${type === layerTypeEnum.mine ? 'col-lg-7' : 'col-lg-9'} cursor-pointer`} style={{ marginLeft: -25, fontSize: 14 }} onClick={() => setChecked('folder', 0, item)} >
                                    <span className="layerName">{item.name}</span>
                                </Col>
                                {type !== layerTypeEnum.sistem &&
                                    <Col className="col-auto col-lg-2 mt-n2">
                                        <LayerDropdown
                                            isLayerDeleted={isLayerDeleted}
                                            setIsLayerDeleted={setIsLayerDeleted}
                                            screenUpdate={getLayerHierarchy}
                                            layerId={item.id}
                                        />
                                    </Col>
                                }
                            </Row>
                            {checkedFolder.indexOf(item.id) !== -1 && item.children.length > 0 && generateChildrenMenu(item.fatherLayerId, item.children, 40, type)}
                        </div>)
                }));
            }
        }
        var classHierarquia = 'camada-hierarquia'
        if (document.querySelector('.camada')?.id == undefined) {
            classHierarquia = 'camada-hierarquia-draw'
        }
        return (
            <div id="camada-hierarquia" className={classHierarquia} >
                {hierarchyArray}
            </div>
        )
    }

    function findAImg(imagem: { caminhoaws: string | null, caminhohttps: string | null, caminhodownload: string | null }) {
        if (imagem?.caminhoaws && imagem.caminhoaws.length) {
            return imagem.caminhoaws
        } else if (imagem?.caminhodownload && imagem.caminhodownload.length) {
            return imagem.caminhodownload
        } else if (imagem?.caminhohttps && imagem.caminhohttps.length) {
            return imagem.caminhohttps
        } else {
            return false
        }
    }

    function generateChildrenMenu(fatherLayerId: any, arrayLayers: Array<any>, marginLeft: number, type: string) {

        var hierarchyArray = new Array<any>();
        marginLeft += 15;
        arrayLayers.forEach((item, index) => {

            var tipoImg = !item.children || !item.children.length ? 'document' : 'folder';
            let srcImg = item.imagem && findAImg(item.imagem) ? findAImg(item.imagem) as string : tipoImg == 'document' ? WhiteDocumentIcon : checkedFolder.indexOf(item.id) !== -1 ? OpenFolderIcon : ClosedFolderIcon
            if (tipoImg == 'document') {
                //documento
                hierarchyArray.push(
                    <div key={`item-menu-${index}`}>
                        <Row className="cursor-pointer justify-content-between">
                            <Col className="p-1 col-auto" style={{ marginLeft: marginLeft }} onClick={() => setChecked(tipoImg, fatherLayerId, item)} >
                                <Image
                                    style={{ float: "left" }}
                                    src={srcImg}
                                    width={20}
                                    fluid />
                                <Form.Check style={{ fontSize: 12, marginLeft: 10, float: "left" }}>
                                    <Form.Check.Input checked={checkedLayer.indexOf(item.id) !== -1} readOnly />
                                    <Form.Check.Label className="layerName">{item.name}</Form.Check.Label>
                                </Form.Check>
                            </Col>
                            {type !== layerTypeEnum.sistem &&
                                <Col className="col-md-1 col-lg-1 col-auto mx-2" style={{ marginTop: -5 }}>
                                    <LayerDropdown
                                        isLayerDeleted={isLayerDeleted}
                                        setIsLayerDeleted={setIsLayerDeleted}
                                        screenUpdate={getLayerHierarchy}
                                        layerId={item.id}
                                    />
                                </Col>
                            }
                        </Row>
                        {checkedLayer.indexOf(item.id) !== -1 && item.children && item.children.length > 0 && generateChildrenMenu(item.id, item.children, marginLeft + 15, type)}
                    </div>
                )
            } else {
                //pasta
                hierarchyArray.push(
                    <div key={`item-menu-${index}`} className="mt-1">
                        <Row style={{ marginLeft: marginLeft }} className="cursor-pointer ">
                            <Col className="col-auto mx-n3 text-left" onClick={() => setChecked(tipoImg, fatherLayerId, item)}>
                                <Image
                                    style={{ float: "left" }}
                                    src={tipoImg == 'document' ? WhiteDocumentIcon : checkedFolder.indexOf(item.id) !== -1 ? OpenFolderIcon : ClosedFolderIcon}
                                    // src={srcImg}
                                    width={20}
                                    fluid />
                                <Form.Check className="mx-1" style={{ fontSize: 12, float: "left" }}>
                                    <Form.Check.Input checked={checkedFolder.indexOf(item.id) !== -1} readOnly />
                                </Form.Check>
                            </Col>
                            <Col className="col-auto col-lg-9 text-left" style={{ fontSize: 14 }} onClick={() => setChecked(tipoImg, fatherLayerId, item)} >
                                <span className="layerName">{item.name}</span>
                            </Col>
                            {type !== layerTypeEnum.sistem &&
                                <Col className="col-auto col-lg-1 mt-n2 mx-2">
                                    <LayerDropdown
                                        isLayerDeleted={isLayerDeleted}
                                        setIsLayerDeleted={setIsLayerDeleted}
                                        screenUpdate={getLayerHierarchy}
                                        layerId={item.id}
                                    />
                                </Col>
                            }
                        </Row>

                        {checkedFolder.indexOf(item.id) !== -1 && item.children && item.children.length > 0 && generateChildrenMenu(item.id, item.children, marginLeft + 15, type)}
                    </div>
                )
            }

        });

        return hierarchyArray;


    }

    function setChecked(type: string, fatherLayerId: any, obj: any) {

        if (type == 'folder') {
            const index = checkedFolder.indexOf(obj.id);
            let checkedFolderArray = [...checkedFolder];
            if (index === -1) {
                checkedFolderArray.push(obj.id);
            } else {
                checkedFolderArray.splice(index, 1);
            }
            setCheckedFolder(checkedFolderArray);
            localStorage.setItem('checkedFolder', JSON.stringify(checkedFolderArray));
            return;
        }
        else if (type == 'document') {
            const index = checkedLayer.indexOf(obj.id);
            let checkedLayerArray = [...checkedLayer];
            if (index === -1) {
                checkedLayerArray.push(obj.id);
            } else {
                checkedLayerArray.splice(index, 1)
            }
            setCheckedLayer(checkedLayerArray || [])
            localStorage.setItem('checkedLayer', JSON.stringify(checkedLayerArray))
            return;
        }
    }


    return (
        <>
            <MapSideBar title={"Camadas"}>
                <>
                    <React.Fragment>
                        <Row className="layerHeader">
                            <Col className={`${layerType === layerTypeEnum.all && 'selected'}`}
                                onClick={() => { setLayerType(layerTypeEnum.all) }}>
                                <span >TODAS</span>
                            </Col>
                            <Col className={`${layerType === layerTypeEnum.sistem && 'selected'}`}
                                onClick={() => {
                                    if (layerType !== layerTypeEnum.sistem) {
                                        clearSelectedFolderAndLayer()
                                    }
                                    setLayerType(layerTypeEnum.sistem)
                                }}>
                                <span >SISTEMA</span>
                            </Col>
                            <Col className={`${layerType === layerTypeEnum.mine && 'selected'}`}
                                onClick={() => {
                                    if (layerType !== layerTypeEnum.sistem) {
                                        clearSelectedFolderAndLayer()
                                    }
                                    setLayerType(layerTypeEnum.mine)
                                }}>
                                <span>MINHAS</span>
                            </Col>

                        </Row>
                        <Row className="defaultSidebar px-4">
                            <Col lg={12} className="default-header mt-2">
                                <h4 className='default-title '>Todas as camadas</h4>
                                <Button className="" onClick={() => setOpenModal(true)}>
                                    <Icon icon={AddIcon} size='12px' />
                                    <span className='px-2'>NOVA CAMADA</span>
                                </Button>
                            </Col>
                        </Row>
                        <Row className='layerSearchBar'>
                            <Col lg={10} className='mt-3'>
                                <InputGroup >
                                    <FormControl
                                        placeholder="Digite sua busca"
                                        value={searchValue}
                                        className='layerSearchInput'
                                        onKeyUp={(e) => e.key === 'Enter' && getLayerHierarchy()}
                                        onChange={(e) => setSearchValue(e.target.value)}
                                    />
                                    <Button variant="outline-dark" className="btn-header-search px-1 layerSearchButton" onClick={async () => { getLayerHierarchy() }}>
                                        <FontAwesomeIcon icon={faSearch} size="lg" />
                                    </Button>
                                </InputGroup>
                            </Col>
                        </Row>
                        {layerType !== layerTypeEnum.mine &&
                            <Row className='defaultSidebar mt-2'>
                                <Col lg={12} className="layerList" >
                                    <Row className='layerTitle' onClick={() => setOpenSistemLayers(!openSistemLayers)}>
                                        <Col className="col-auto col-lg-2">
                                        </Col>
                                        <Col className="titleText col-auto col-lg-8">
                                            <span> Camadas do Sistema</span>
                                        </Col>
                                    </Row>
                                </Col>
                                {layerType !== layerTypeEnum.mine && hierarchyLayerList?.sistemLayer && generateMenu(layerTypeEnum.sistem)}
                            </Row>}
                        {layerType !== layerTypeEnum.sistem &&
                            <Row className='defaultSidebar mt-2'>
                                <Col lg={12} className="layerList" >
                                    <Row className='layerTitle' >
                                        <Col className="col-auto col-lg-2" onClick={() => setOpenUserLayers(!openUserLayers)} >
                                        </Col>
                                        <Col className="titleText col-auto col-lg-8" onClick={() => setOpenUserLayers(!openUserLayers)}>
                                            <span>Camadas do Usuario</span>
                                        </Col>
                                    </Row>
                                </Col>
                                {layerType !== layerTypeEnum.sistem && hierarchyLayerList?.userLayer && generateMenu(layerTypeEnum.mine)}
                            </Row>}


                    </React.Fragment>
                    {openModal &&
                        <ModalLayerComponent
                            openModal={openModal}
                            setOpenModal={setOpenModal}
                            selectedItem={selectedItem}
                            setSelectedItem={setSelectedItem}
                            screenUpdate={getLayerHierarchy}
                        />}
                </>
            </MapSideBar>
        </>
    );
};

export default LayerSidebar;
