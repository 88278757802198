import { SidebarItemsType } from "./interfaces/sidebar";
import DashboardIcon from '../../assets/img/dashboard.svg';
import MapIcon from '../../assets/img/map.svg';
import ReportsIcon from '../../assets/img/reports.svg';
import OccupationAreaIcon from '../../assets/img/occupationArea.svg';
import LayerIcon from '../../assets/img/layer.png';

const generalMenu = [
  {
    href: "/estatisticas",
    icon: DashboardIcon,
    title: "Dashboard",
  },
  {
    href: "/mapa",
    icon: MapIcon,
    title: "Mapa",
    mapSideBar: ''
  },
  {
    href: "/mapa",
    icon: ReportsIcon,
    title: "Relatórios",
    mapSideBar: 'relatorios'
  },
  {
    href: "/mapa/occupationArea",
    icon: OccupationAreaIcon,
    title: "Área de Atuação",
    mapSideBar: 'occupationArea'
  }
] as SidebarItemsType[];



const navItems = [
  {
    title: "",
    pages: generalMenu,
  },
];

export default navItems;