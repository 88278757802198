import React from 'react';

interface IconProps {
    icon: string;
    text?: string;
    size?: string;
    className?: string;
    width?: string;
    height?: string;
}

const Icon: React.FC<IconProps> = ({ icon, text, size, className, height, width }) => {
    let styledSize

    if (size) {
        styledSize = {
            width: size,
            height: size,
        };
    } else if (width) {
        styledSize = {
            width,
            height,
        };
    }

    return <img className={className} src={icon} alt={text} style={styledSize} />;
};

export default Icon;
