import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import useToken from "../../utils/hooks/useToken";

import { unsetSession } from "../../utils/token";
import Avatar from "../lists/Avatar";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


interface NavbarUserProps {
  withBackground?: boolean;
}

const NavbarUser: React.FC<NavbarUserProps> = ({ withBackground }) => {
  const { user } = useToken();
  const navigate = useNavigate();

  var userImage = "";
  if (user?.image) userImage = user.image;

  return (
    <Dropdown className={`nav-item  ${withBackground ? '' : 'mapDropdown'}`} align="end" >
      <span >
        <Dropdown.Toggle as="a" className={`nav-link ${withBackground ? 'nav-dashboard' : ''}`}>
          <FontAwesomeIcon icon={faBars} size={'lg'} className="navbarIcon" />
          <span className="pe-2"></span>
          <Avatar
            src={/*userImage ? baseURL + "/uploads/photos/" + userImage :*/ "https://cdn.awsli.com.br/600x450/549/549871/produto/29108392/60cdfb3799.jpg"}
            name={user?.name}
            showName={false}
            size="small"
          />
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() =>/*navigate("/usuarios/" + user?.uuidLogin + "/")*/ navigate('/inConstruction')}>
          Minha conta
        </Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/inConstruction")}>
          Configurações
        </Dropdown.Item>
        <Dropdown.Item onClick={unsetSession}>
          Sair
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
