import { Nav, Navbar } from "react-bootstrap";

import useFilterContext from "../../utils/hooks/useFilter";
import SelectComponent from "../Select";
import useSidebar from "../sidebar/hooks/useSidebar";
import NavbarUser from "./NavbarUser";
import { daysFilter } from "../../beans/enumerators";

interface NabBarProps {
  title?: string
  withFilter?: boolean
}


const NavbarComponent: React.FC<NabBarProps> = ({ title, withFilter }) => {
  const { isOpen, setIsOpen } = useSidebar();
  const { setValue } = useFilterContext();

  function handleFilters(value: any) {
    setValue(value);
  }

  return (
    <Navbar variant="light" expand className="navbar-bg">
      <span
        className="sidebar-toggle d-flex d-table-cell d-lg-none"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <i className="hamburger align-self-center" />
      </span>
      <h2>
        {title}
      </h2>
      {withFilter &&
        <div className="btn-header-search">
          <SelectComponent
            options={daysFilter}
            onChange={(e) => handleFilters(e)}
            className="nav-selector black-border"
          />
        </div>
      }

      <Navbar.Collapse>
        <Nav className="navbar-align">
          <NavbarUser withBackground />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
