import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { itens_per_page } from "../../../beans/enumerators";
import PaginationCustom from "../../../components/lists/Pagination";
import useLoadingContext from "../../../utils/hooks/useLoading";
import DashBoardSideBars from "../components/DashBoardSideBars";
import { PollingStatus } from "./interfaces";
import { getPollingList } from "./services/shapePolling";

const PollingPage = () => {

    const [pollingList, setPollingList] = useState<Array<PollingStatus>>([])
    const [nList, setNList] = useState<number>(0);
    const [pagination, setPagination] = useState<number>(1);
    const { setIsLoading } = useLoadingContext();
    const [showSidebar, setShowSidebar] = useState(true);
    const [filters, setFilters] = useState<any>({
        pagination: "1",
    });
    useEffect(() => {
        screenUpdate()
    }, [])

    async function screenUpdate() {
        let data = {
            page: filters.pagination,
            limit: itens_per_page.default,
        }
        setIsLoading(true)
        await getPollingList(data).then((response) => {
            if (response) {
                setPollingList(response.result)
                setNList(response.total)
                response.total > 0 ? setPagination(Math.ceil(response.total / itens_per_page.default)) : setPagination(0);
            }
        }).catch(() => {
            setIsLoading(false)
        })
        setIsLoading(false)
    }

    function verifyFilters(key: string, newValue: string) {
        let newFilters = filters;
        newFilters[key] = newValue;
        if (key !== "pagination") newFilters["pagination"] = "1";
        setFilters(newFilters);
        screenUpdate()
    }

    return (
        <>
            <Helmet title="Importação" />
            <Row className="dashboard" >

                <DashBoardSideBars
                    navigateTo={'polling'}
                    setShowSidebar={setShowSidebar}
                    showSidebar={showSidebar} />


                <Col className={`dashboardCards card col-auto col-lg-10 col-md-9 ${showSidebar ? 'col-sm-9' : 'col-sm-11'} dashboardBody listSize`}>
                    <Row>
                        <Col xs={12} lg={10} >
                            <h2>Status da importação</h2>
                        </Col>
                    </Row>
                    <Table className="mt-5 trBorder">
                        <thead>
                            <tr>
                                <th style={{ minWidth: '50px', width: '50px' }}>Data</th>
                                <th>Hora</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!!pollingList && pollingList.map((item, key) => (
                                <tr className="trBorder" key={key}>
                                    <td>{moment(item.date).format('DD/MM/YYYY')}</td>
                                    <td>{moment(item.date).format('HH:mm:ss')}</td>
                                    <td>{item.status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    <PaginationCustom
                        pagination={pagination}
                        filter={filters.pagination}
                        verifyFilters={verifyFilters}
                        total={nList}
                    />
                </Col>
            </Row>
        </>
    );
};

export default PollingPage;
