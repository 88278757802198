
import shapeApi, { relatoryApi } from "../../../utils/axios";
import { SuccessResponse } from "../../../utils/interfaces/response";
import { FeatureCollection } from "../interfaces";

export async function listShapes(data: any): Promise<FeatureCollection> {
	return shapeApi.post('/shape/findByArrayOfLayers/', { ...data }).then(response => {
		return response.data
	})
}

export async function createShape(data: any): Promise<SuccessResponse> {
	return await shapeApi.post('/shape/create/', data).then(response => {
		return response.data
	})
}

export async function deleteShape(id: any): Promise<SuccessResponse> {
	return await shapeApi.delete(`/shape/delete/?id=${id}`).then(response => {
		return response.data
	})
}

export async function getShapeAtributes(id: any): Promise<any> {
	return await relatoryApi.get(`/shape-attributes/findByShape/?id=${id}`).then(response => {
		return response.data
	})
}