
import { FileUpload } from 'primereact/fileupload';

interface FileUploadComponentProps {
    setFiles?: any
}

export default function FileUploadComponent({ setFiles }: FileUploadComponentProps) {

    const onUpload = (e: any) => {
        console.log(e)
        setFiles(e.files)
    };

    return (
        <FileUpload
            name="files[]"
            customUpload={true}
            maxFileSize={100000000} /* 100mb */
            uploadHandler={(e) => onUpload(e)}
            chooseLabel="Selecione os arquivos"
            emptyTemplate={<p >Selecione ou arraste os arquivos</p>}
            multiple
            auto
        />
    )
}