import React from 'react';


interface PageTitleProps {
  icon?: string;
  text: string;
  }

const PageTitle: React.FC<PageTitleProps> = ({icon, text}) => {

    return (
        <div className="d-flex align-items-center ts-page-title px-2">
            {icon && (<img src={icon} alt={text}/>)}
            <h2>{text}</h2>
        </div>
    );
}

export default PageTitle;