import { Dispatch, SetStateAction, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import OptionsIcon from "../../../../assets/img/options.png";
import { alertType } from '../../../../beans/enumerators';
import CustomAlert from '../../../../components/Alert';
import Icon from '../../../../components/Icon';
import useLoadingContext from '../../../../utils/hooks/useLoading';
import ModalLayerComponent from '../../../Dashboard/Layer/components/ModalLayerComponent';
import { deleteLayer } from '../../../Dashboard/Layer/services/layers';

interface LayerDropdownProps {
    layerId: number
    screenUpdate: any
    isLayerDeleted: boolean
    setIsLayerDeleted: Dispatch<SetStateAction<boolean>>
}

const LayerDropdown = ({ layerId, screenUpdate, setIsLayerDeleted }: LayerDropdownProps) => {
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [openAlert, setOpenAlert] = useState<boolean>(false)
    const { setIsLoading } = useLoadingContext();
    const [selectedItem, setSelectedItem] = useState<number>()

    async function handleDelete() {
        setIsLoading(true)
        await deleteLayer(layerId).then(() => {
            let checkedLayers = JSON.parse(String(localStorage.getItem('checkedLayer'))) as Array<number>
            checkedLayers = checkedLayers.filter((item) => item != layerId)
            localStorage.setItem('checkedLayer', JSON.stringify(checkedLayers))
            setIsLayerDeleted(true)
            toast.success(alertType.deleted)
            screenUpdate()
        }).catch(() => {
            setIsLoading(false)
        })
        setIsLoading(false)
        handleCloseAlert()
        screenUpdate()
    }

    function handleCloseAlert() {
        setOpenAlert(false)
        setSelectedItem(0)
    }

    return (
        <>
            <Dropdown className="nav-item no-bg align-end">
                <Dropdown.Toggle className='nav-link'>
                    <Icon icon={OptionsIcon} size='22px' className='close-icon' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => { setOpenModal(true); setSelectedItem(layerId) }}>
                        Editar camada
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => { setOpenAlert(true); setSelectedItem(layerId) }}>
                        Deletar camada
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>


            <CustomAlert
                message={alertType.delete + 'essa camada?'}
                show={openAlert}
                onYes={handleDelete}
                onNo={handleCloseAlert}
                onHide={handleCloseAlert}
            />

            {!!selectedItem &&
                <ModalLayerComponent
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                    screenUpdate={screenUpdate}
                />}
        </>
    )
}

export default LayerDropdown