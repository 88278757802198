import { Formik } from 'formik';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { RelatoryColors } from '../../../beans/enumerators';
import MapSideBar from '../components/MapSideBar';
import BarChart from './components/BarChart';
import GroupedBarChart from './components/GroupedBarChart';
import PieChart from './components/PieChart';
import RadarChart from './components/RadarChart';

const RelatorySidebar = () => {

    function handleDownload() {
        console.log('imprimiu')
    }

    const initialValues = {
        exemple: ''
    }

    return (
        <MapSideBar
            title={"Filtros"}>
            <Formik
                initialValues={initialValues}
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);
                    //chama rota da api


                }}>{() => (
                    <React.Fragment>
                        <Row className="relatorySidebar">
                            <Col lg={12} className="default-header my-2">
                                <h4 className='text'>Exemplo de gráfico</h4>
                            </Col>
                            <Col lg='12' className="relatory-box">
                                <BarChart colors={RelatoryColors} />
                            </Col>
                        </Row>
                        <Row className="relatorySidebar">
                            <Col lg={12} className="default-header my-2">
                                <h4 className='text'>Exemplo de gráfico</h4>
                            </Col>
                            <Col lg='12' className="relatory-box">
                                <GroupedBarChart colors={RelatoryColors} />
                            </Col>
                        </Row>
                        <Row className="relatorySidebar">
                            <Col lg={12} className="default-header my-2">
                                <h4 className='text'>Exemplo de gráfico</h4>
                            </Col>
                            <Col lg='12' className="relatory-box">
                                <RadarChart colors={RelatoryColors} />
                            </Col>
                        </Row>
                        <Row className="relatorySidebar">
                            <Col lg={12} className="default-header my-2">
                                <h4 className='text'>Exemplo de gráfico</h4>
                            </Col>
                            <Col lg='12' className="relatory-box last-box">
                                <PieChart colors={RelatoryColors} />
                            </Col>
                        </Row>
                        <Row className='fixed-button-container'>
                            <Col lg={12} className='p-3' >
                                <Button className="printButton" onClick={handleDownload}>
                                    BAIXAR
                                </Button>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
            </Formik>
        </MapSideBar >

    );
};

export default RelatorySidebar;
