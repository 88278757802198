import React from "react";
import { NavLink } from "react-router-dom";
import Icon from "../Icon";
import SettingsIcon from "../../../src/assets/img/setting.png"
import { Col, Row } from "react-bootstrap";

const SidebarFooter = () => {
  return (
    <Col className="sidebar-footer sidebar-nav" onClick={() => { console.log('não definido') }}>
      <NavLink to={'/inConstruction'} className="sidebar-link d-flex flex-column align-items-center">
        <Icon icon={SettingsIcon} size={'20px'} />
        <span className="align-justify" >
          Ajustes
        </span>
      </NavLink>
    </Col>
  );
};

export default SidebarFooter;
