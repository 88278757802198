import L from 'leaflet';
import { useState } from 'react';
import { FeatureGroup } from 'react-leaflet';
import { EditControl } from "react-leaflet-draw";
import { DefaultGeometryType } from '../../../beans/enumerators';
import ModalCreateShape from './ModalCreateShape';

interface MapButtonsProps {
    getShapes: () => void
}

export interface ShapeData {
    coordinates: any
}

function MapDrawTools({ getShapes }: MapButtonsProps) {

    const [openModal, setOpenModal] = useState<boolean>(false)
    const [shapeData, setShapeData] = useState<ShapeData>({ coordinates: [] })
    const [shapeType, setShapeType] = useState<string>('')
    const [idDrawShape, setIdDrawShape] = useState<any>()
    const defaultIcon = new L.Icon({
        iconUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
        iconSize: [25, 35],
    });

    const _onCreate = (e: any) => {
        setIdDrawShape(e.layer)
        var type = e.layerType.charAt(0).toUpperCase() + e.layerType.slice(1).toLowerCase()
        var cords
        if (type === 'Marker') {
            cords = e.layer._latlng
        } else if (type === "Polyline") {
            cords = e.layer._latlngs
            type = DefaultGeometryType.line
        } else {
            cords = (e.layer._latlngs)[0]
            cords.push(cords[0]);
        }

        setShapeType(type)
        setShapeData({ coordinates: cords })
        setOpenModal(true)
    }

    const _onDeleted = (e: any) => {
        console.log(e)
    }

    const _onEdit = (e: any) => {
        console.log(e)
    }
    return (
        <>
            <FeatureGroup >
                <EditControl
                    position="topleft"
                    onCreated={_onCreate}
                    onDeleted={_onDeleted}
                    onEdited={_onEdit}
                    edit={{
                        remove: false
                    }}
                    draw={{
                        rectangle: false,
                        circle: false,
                        polygon: true,
                        polyline: true,
                        marker: {
                            icon: defaultIcon
                        },
                        circlemarker: false,

                    }}
                />
            </FeatureGroup>
            {!!openModal &&
                <ModalCreateShape
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    shapeCoordinate={shapeData}
                    shapeType={shapeType}
                    getShapes={() => getShapes()}
                    idDrawShape={idDrawShape}
                />}
        </>
    );
}

export default MapDrawTools;
