import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface CustomAlertProps {
    show: boolean
    onHide?: () => void
    onYes?: () => void
    onNo?: () => void
    onCancel?: () => void,
    message: string
}

const CustomAlert = ({ show, onHide, onYes, onNo, onCancel, message }: CustomAlertProps) => {
    return (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Atenção</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span className='alert-text'>{message}</span>
            </Modal.Body>
            <Modal.Footer>
                {onCancel && <Button variant="warning" size='lg' onClick={onCancel}>CANCELAR</Button>}
                {onNo && <Button variant="danger" size='lg' onClick={onNo}>NÃO</Button>}
                {onYes && <Button className='btn-blue modalSubmi-btn' size='lg' onClick={onYes}>SIM</Button>}
            </Modal.Footer>
        </Modal>
    );
};

export default CustomAlert;
