import { Formik } from "formik";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { alertType } from "../../../../beans/enumerators";
import PageTitle from "../../../../components/PageTitle";
import MultiSelect from "../../../../components/SelectSearch";
import useLoadingContext from "../../../../utils/hooks/useLoading";
import { ListResponse } from "../../../../utils/interfaces/response";
import { getLayersListChilds } from "../../../Dashboard/Layer/services/layers";
import { OccupationAreaInterface } from "../interfaces";
import { createOccupationArea, getOccupationArea, updateOccupationArea } from "../services";
import { getUserGroupsList } from "../../../Dashboard/UserGroup/services";

interface ModalOccupationAreaComponentProps {
    openModal: boolean
    setOpenModal: Dispatch<SetStateAction<boolean>>
    screenUpdate: () => void
    setSelectedItem: Dispatch<any>
    selectedItem?: any
}

const ModalOccupationAreaComponent: React.FC<ModalOccupationAreaComponentProps> = ({ openModal, setOpenModal, selectedItem, setSelectedItem, screenUpdate }) => {

    const { setIsLoading } = useLoadingContext();
    const [layerList, setLayerList] = useState<Array<ListResponse>>([])
    const [selectedLayers, setSelectedLayers] = useState<Array<ListResponse>>([])

    const [userGroupList, setUserGroupList] = useState<Array<ListResponse>>([])
    const [selectedUserGroup, setSelectedUserGroup] = useState<Array<ListResponse>>([])
    const [initialValues, setInitialValues] = useState<OccupationAreaInterface>({
        name: ''
    })

    useEffect(() => {
        getLayersListChilds().then(setLayerList).catch(() => {
            setIsLoading(false)
        })
        getUserGroupsList().then(setUserGroupList).catch(() => {
            setIsLoading(false)
        })
    }, [])

    async function handleCreate(values: OccupationAreaInterface) {
        let data = {
            ...values,
            layers: selectedLayers.map((item) => {
                return item.value
            }),
            userGroup: selectedUserGroup.map((item) => {
                return Number(item.value)
            }),
            users: []
        }
        setIsLoading(true);
        await createOccupationArea(data).then(() => toast.success(alertType.created)).catch(() => {
            setIsLoading(false)
        })
        screenUpdate();
        setIsLoading(false);
        handleCloseModal();
    }

    async function handleEdit(values: OccupationAreaInterface) {
        let data = {
            id: selectedItem,
            ...values,
            layers: selectedLayers.map((item) => {
                return item.value
            }),
            userGroup: selectedUserGroup.map((item) => {
                return item.value
            })
        }
        setIsLoading(true);
        await updateOccupationArea(data).then(() => toast.success(alertType.updated)).catch(() => {
            setIsLoading(false)
        })
        screenUpdate();
        setIsLoading(false);
        handleCloseModal();
    }

    useEffect(() => {
        if (selectedItem) {
            getOccupationArea(selectedItem).then((response) => {
                if (response) {
                    setInitialValues({
                        name: response.name,
                    })
                    let selectedLayers: any = []
                    response.layers.map((item) => {
                        selectedLayers.push({
                            value: item.id,
                            label: item.name
                        })
                    })
                    setSelectedLayers(selectedLayers)
                    let selectedUserGroup: any = []
                    response.userGroup.map((item) => {
                        selectedUserGroup.push({
                            value: item.id,
                            label: item.name
                        })
                    })
                    setSelectedUserGroup(selectedUserGroup)
                } else {
                    setInitialValues({
                        name: '',
                    });
                }
            }).catch(() => {
                setIsLoading(false)
            })
        }
    }, [selectedItem])

    function handleCloseModal() {
        setOpenModal(false)
        setSelectedItem('')
    }

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={
                Yup.object().shape({
                    name: Yup.string()
                })
            }
            onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                if (selectedItem) {
                    handleEdit(values)
                } else {
                    handleCreate(values)
                }
                resetForm({ values: { ...initialValues } });

            }}>{({ handleBlur, handleChange, handleSubmit, isSubmitting, values, errors }) => (
                <Modal dialogClassName="ts-modal large-modal" show={openModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <PageTitle text={`${!!selectedItem ? 'Editar' : 'Adicionar'} Área de atuação`} />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-occupation-area">
                        <Form onSubmit={handleSubmit} className="px-2 " >
                            <Form.Group className="mb-3">
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    size="lg"
                                    required
                                    name="name"
                                    className="inputBorderBottom"
                                    value={values.name}
                                    onChange={handleChange}
                                    placeholder="Nome"
                                />
                            </Form.Group>
                            <Row>
                            <Col lg={6} className="mt-4">
                                    <MultiSelect
                                        options={layerList}
                                        placeholder="Selecione as camadas"
                                        word={"Camadas"}
                                        labelText={"Selecione as camadas"}
                                        selectedOptions={selectedLayers}
                                        setSelectedOptions={setSelectedLayers}
                                        withList
                                    />
                                </Col>
                                <Col lg={6} className="mt-4">
                                    <MultiSelect
                                        options={userGroupList}
                                        placeholder="Selecione os grupos de úsuarios"
                                        word={"Grupos de Úsuarios"}
                                        labelText={"Selecione os grupos de úsuarios"}
                                        selectedOptions={selectedUserGroup}
                                        setSelectedOptions={setSelectedUserGroup}
                                        withList
                                    />
                                </Col>
                            </Row>
                            <Row className="modal-newlayer-footer">
                                <hr />
                                <Col className="d-flex justify-content-end">
                                    <Button className="redbg ts-link-icon mx-2" onClick={handleCloseModal}> CANCELAR</Button>
                                    <Button
                                        type="submit"
                                        className="primary-btn modalSubmi-btn"
                                        disabled={isSubmitting}
                                    >
                                        {selectedItem ? 'EDITAR' : 'CRIAR'}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                </Modal >
            )}
        </Formik>
    )
}

export default ModalOccupationAreaComponent;