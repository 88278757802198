
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet/dist/leaflet.css';
import { Attributes, Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { LayersControl, MapContainer, TileLayer, ZoomControl } from 'react-leaflet';
import HideIcon from "../../../assets/img/hide.png";
import { tiles } from '../../../beans/mapTiles';
import IconComponent from "../../../components/Icon";
import useLoadingContext from '../../../utils/hooks/useLoading';
import { FilterData } from '../Filters/interfaces';
import { FeatureCollection } from '../interfaces';
import { listShapes } from '../services';
import MapButtons from './MapButtons';
import MapDrawTools from './MapDrawTools';
import ShowShapes from './ShowShapes';

interface MapComponentProps {
  checkedLayer: Array<number>
  setCheckedLayer: Dispatch<SetStateAction<any[]>>
  openDrawOptions: boolean;
  setOpenDrawOptions: React.Dispatch<React.SetStateAction<boolean>>;
  isLayerDeleted: boolean
  setIsLayerDeleted: Dispatch<SetStateAction<boolean>>
  selectedFilters: any
  haveFilter: boolean
  setHaveFilter: Dispatch<SetStateAction<boolean>>
}

const MapComponent: React.FC<MapComponentProps> = ({ openDrawOptions, checkedLayer, setCheckedLayer, setOpenDrawOptions, isLayerDeleted, setIsLayerDeleted, selectedFilters, haveFilter, setHaveFilter }) => {

  const { setIsLoading } = useLoadingContext();
  const mapRef = useRef(null)
  const [shapesList, setShapesList] = useState<FeatureCollection>({
    type: "FeatureCollection",
    features: [],
    style: {}
  });

  useEffect(() => {
    let checkedLayers = JSON.parse(String(localStorage.getItem('checkedLayer')))
    checkedLayers && setCheckedLayer(checkedLayers)
  }, [])

  useEffect(() => {
      getShapes()
  }, [checkedLayer])

  useEffect(() => {
    if (isLayerDeleted) {
      getShapes()
      setIsLayerDeleted(false)
    }
  }, [isLayerDeleted])

  useEffect(() => {
    if (haveFilter && selectedFilters) {
      getShapes(selectedFilters)
      setHaveFilter(!haveFilter)
    }
  }, [haveFilter])


  async function getShapes(filters?: Attributes[]) {
    setIsLoading(true)
    let checkedLayers = JSON.parse(String(localStorage.getItem('checkedLayer')))
    if (!!checkedLayers) {
      let data: FilterData = {
        layersIds: checkedLayers,
        filters: {
          attributes: []
        },
      };
      if (!!filters?.length) {
        (data.filters.attributes as Attributes[]) = filters
      }
      await listShapes(data).then((response) => {
        if (response) {
          setShapesList(response)
        }
      }).catch(() => {
        setIsLoading(false)
    })
    }
    setIsLoading(false)

  }

  return (
    <MapContainer ref={mapRef} center={[-22.2154042, -54.8331331]} zoom={3} scrollWheelZoom={(true)} className='map' zoomControl={false}>
      <ZoomControl position="bottomright" />

      <MapButtons
        openDrawOptions={openDrawOptions}
        setOpenDrawOptions={setOpenDrawOptions}
        mapRef={mapRef}
      />

      {!!openDrawOptions &&
        <MapDrawTools
          getShapes={() => getShapes()}
        />
      }
      <Row>
        <Col lg={11} />
        <Col lg={1} className='bottomrightButton'>
          <Button
            className="border" onClick={() => { console.log('hiden', mapRef) }}>
            <IconComponent icon={HideIcon} size='16px' />
          </Button>
        </Col>
      </Row>
      <LayersControl position="topright">
        {tiles.map(({ checked, name, url }) => {
          const tileLayerProps = { url, name, };

          return (
            <LayersControl.BaseLayer
              checked={!!checked}
              key={name}
              name={name}
            >
              <TileLayer maxNativeZoom={19} {...tileLayerProps} />
            </LayersControl.BaseLayer>
          );
        })}
      </LayersControl>
      <ShowShapes
        shapesList={shapesList}
        getShapes={() => getShapes()}
      />
    </MapContainer>
  );
};

export default MapComponent;
