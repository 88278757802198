export enum accountType {
  MASTER = "MASTER",
  ATTENDANT = "ATTENDANT"
}

export enum statusCode {
  ok = 200,
  created = 201,
  notUpdated = 202,
  noContent = 204,
  notOk = 400,
  notFound = 404,
  crashError = 500
}

export enum itens_per_page {
  default = 10
};


export const daysFilter = [
  { text: "Hoje", value: 0 },
  { text: "Últimos 2 dias", value: 2 },
  { text: "Últimos 7 dias", value: 7 },
  { text: "Últimos 15 dias", value: 15 },
  { text: "Últimos 30 dias", value: 30 },
];


export const RelatoryColors = [
  '#165BAA',
  '#F765A3',
  '#A155B9',
  '#16BFD6',
  '#1DDD8D'
];


export const mapSideBarContextEnum = {
  occupationArea: "occupationArea",
  relatories: "relatories",
  layers: "layers",
  shape: "shape",
  filters: "filters"
};

export const layerTypeEnum = {
  all: "all",
  sistem: "sistem",
  mine: "mine"
}


export const shapeMenuEnum = {
  info: "I",
  contact: "C",
  star: "S",
  bars: "B",
  export: "E"
}

export const alertType = {
  delete: "Tem certeza que deseja excluir ",
  deleted: "Registro excluido com sucesso!",
  deletedError: "Erro ao excluir registro",
  created: "Criado com sucesso!",
  createdError: "Erro ao criar registro!",
  updated: "Atualizado com sucesso!",
  updatedError: "Erro ao atualizar!",
  uploaded: "Arquivo enviado com sucesso!",
  error: "Erro ao cadastrar!",
  polling: "Seus arquivos estão sendo processados, aguarde!",
  fileError: "Erro ao importar arquivos!",
  pollingSuccess: "Arquivos importados com sucesso!",
  noLayerSelected: "É necessário ter uma camada selecionada para realizar esta ação.",
  shapeNotSupported: "Esta camada não suporta esse tipo de shape",
}


export const intervalTimer = {
  ten: 10000
}

export const GeometryType = [
  { text: "Ponto", value: "P" },
  { text: "Poligono", value: "G" },
  { text: "Linha", value: 'L' }
]

export enum GeometryTypeEnum {
  point = "Point",
  polygon = "Polygon",
  line = "LineString",
  circle = "circle",
  rectangle = "rectangle",
  multiPolygon = "MultiPolygon",
  multiLineString = "MultiLineString"
}

export enum GeometryTypeShortedEnum {
  P = "P",
  L = "L",
  G = "G"
}

export enum DefaultGeometryType {
  point = "Marker",
  polygon = "Polygon",
  line = "LineString",
  circle = "circle",
  rectangle = "rectangle"
}


export enum DefaultLayerStyle {
  color = "#235dad",
  colorOpacity = 1,
  lineHeight = 2,
  filledColor = "#235dad",
  filledColorOpacity = 0.4
}


export enum LineKey {
  firstLine = "KeyIndicators",
  secondLine = "AgriculturalData",
  thirdLine = "ProductionLocation",
  fourthLine = "MachineryOperations",
  TimePrevision = "time-prevision"
}