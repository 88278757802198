import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Col, Nav, NavItem, NavLink, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ChartIcon from '../../../assets/img/chart.png';
import ImportIcon from "../../../assets/img/import.png";
import LayerIcon from '../../../assets/img/layers.png';
import UserIcon from '../../../assets/img/user.png';
import userGroupIcon from '../../../assets/img/userGroup.png';
import Icon from "../../../components/Icon";
import HamburguerIcon from "../../../assets/img/hamburguer.png";

interface DashBoardSideBarsProps {
    navigateTo: string
    setShowSidebar: Dispatch<SetStateAction<boolean>>
    showSidebar: boolean
}

const DashBoardSideBars = ({ navigateTo, setShowSidebar, showSidebar }: DashBoardSideBarsProps) => {
    const [activeItem, setActiveItem] = useState(navigateTo);
    const navigate = useNavigate(); // Obtendo a função de navegação

    useEffect(() => {
        const handleResize = () => {
            setShowSidebar(window.innerWidth >= 1256);
        };
        handleResize();
    }, []);

    function handleItemClick(item: string) {
        setActiveItem(item);
        switch (item) {
            case "estatisticas":
                navigate("/estatisticas");
                break;
            case "camadas":
                navigate("/camadas");
                break;
            case "usuarios":
                navigate("/usuarios");
                break;
            case "grupousuarios":
                navigate("/grupousuarios");
                break;
            case "polling":
                navigate("/polling");
                break;
            default:
                break;
        }
    };
    return (
        (showSidebar ?
            <Col className="mt-3 col-auto col-lg-2 col-md-2 col-sm-2 sidebarSize">
                <span
                    className="sidebar-toggle d-flex"
                    onClick={() => {
                        setShowSidebar(!showSidebar);
                    }}
                >
                    <Icon icon={HamburguerIcon} />
                </span>

                <div className="dashNavItens">
                    <Nav style={{ flexDirection: 'column' }}>
                        <NavItem onClick={() => handleItemClick("estatisticas")}>
                            <NavLink className={`dashNavItens-link ${activeItem === 'estatisticas' && 'actived-dashboardMenu'}`}>
                                <Icon icon={ChartIcon} size="20px" />
                                <span className="px-2">Estatísticas</span>
                            </NavLink>
                        </NavItem>
                        <NavItem onClick={() => handleItemClick("camadas")}>
                            <NavLink className={`dashNavItens-link ${activeItem === 'camadas' && 'actived-dashboardMenu'}`}>
                                <Icon icon={LayerIcon} size="20px" />
                                <span className="px-2">Camadas</span>
                            </NavLink>
                        </NavItem>
                        <NavItem onClick={() => handleItemClick("usuarios")}>
                            <NavLink className={`dashNavItens-link ${activeItem === 'usuarios' && 'actived-dashboardMenu'}`}>
                                <Icon icon={UserIcon} size="20px" />
                                <span className="px-2">Usuários</span>
                            </NavLink>
                        </NavItem>
                        <NavItem onClick={() => handleItemClick("grupousuarios")}>
                            <NavLink className={`dashNavItens-link ${activeItem === 'grupousuarios' && 'actived-dashboardMenu'}`}>
                                <Icon icon={userGroupIcon} size="20px" />
                                <span className="px-2">Grupos</span>
                            </NavLink>
                        </NavItem>
                        <NavItem onClick={() => handleItemClick("polling")}>
                            <NavLink className={`dashNavItens-link ${activeItem === 'polling' && 'actived-dashboardMenu'}`}>
                                <Icon icon={ImportIcon} size="20px" />
                                <span className="px-2">Importação</span>
                            </NavLink>
                        </NavItem>

                    </Nav>
                </div>
            </Col>
            :
            <Col className="mt-3 col-auto ">
                <span
                    className="sidebar-toggle d-flex mx-1 mr-2 mt-3"
                    onClick={() => {
                        setShowSidebar(!showSidebar);
                    }}
                >
                    <Icon icon={HamburguerIcon} />
                </span>
            </Col>
        )
    )
};

export default DashBoardSideBars;
