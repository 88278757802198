import { AxiosResponse } from "axios";

import { statusCode } from "../../../beans/enumerators";
import shapeApi from "../../../utils/axios";
import { setSession } from "../../../utils/token";
import { verifyResponse } from "../../../utils/verify-response";
import { SignInData } from "../interfaces/auth";

export async function signInApi(userData: SignInData): Promise<AxiosResponse> {
	return shapeApi.post('/login/', userData).then(response => {
		if (typeof response.data === 'string') {
			setSession(response.data);
			return {
				status: statusCode.ok,
				statusText: response.data,
				data: {},
				headers: {},
				config: {}
			};
		}
		return verifyResponse(response);
	}).catch(error => {
		return verifyResponse(error);
	});
}

export async function resetPasswordApi(email: string): Promise<AxiosResponse> {
	return shapeApi.put('/login/recover/' + email).then(response => {
		return verifyResponse(response);
	}).catch(error => {
		return verifyResponse(error);
	});
}

export async function newPasswordApi(hash: string, password: string): Promise<AxiosResponse> {
	return shapeApi.put('/login/change/', { hash, password }).then(response => {
		return verifyResponse(response);
	}).catch(error => {
		return verifyResponse(error);
	});
}