import React, { useEffect } from 'react';
import Chart from 'react-apexcharts';

interface PieChartProps {
    colors: Array<string>;
}

const PieChart = ({ colors }: PieChartProps) => {

    const options: any = {
        charts: {
            height:231
        },
        colors: colors,
        labels: ['Product 1', 'Product 2'],
        fill: {
            colors: colors,
            opacity: 1,
        },
        legend: {
            position: 'right',
            fontSize: '10px',
            itemMargin: {
                horizontal: 10,
                vertical: 5
            },
            markers: {
                height: 8,
                width: 13,
                radius: 0,
            }
        },
    }

    return (
        <Chart
            type="pie"
            series={[25, 75]}
            options={options}
        >
        </Chart >
    )
};

export default PieChart;
