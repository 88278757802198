import React, { useState } from "react";

const initialState = {
    value: '',
    setValue: (value: string) => { },
};

const SideBarMapContext = React.createContext(initialState);

interface SideBarMapProviderProps {
    children: React.ReactNode;
}

function SideBarMapProvider({ children }: SideBarMapProviderProps) {
    const [value, setValue] = useState(initialState.value);
    
    return (
        <SideBarMapContext.Provider
            value={{
                value,
                setValue,
            }}
        >
            {children}
        </SideBarMapContext.Provider>
    );
}

export { SideBarMapContext, SideBarMapProvider };

