import React from "react";
import { Button, Navbar } from "react-bootstrap";

import useSidebar from "../sidebar/hooks/useSidebar";
import Icon from "../Icon";
import MenuIcon from "../../assets/img/menu.png"

const NavbarComponent = () => {
  const { isOpen, setIsOpen } = useSidebar();

  return (
    <Button onClick={() => { setIsOpen(!isOpen) }}  >
      <Icon icon={MenuIcon} size="20px" />
    </Button>
  );
};

export default NavbarComponent;
