
const tokenMapBox = "pk.eyJ1IjoiZGFuaWVscmFtYm8iLCJhIjoiY2o3ejRveWFtNDdhZDJ3bzM1aXp0YTQxZiJ9.ZJ3EXfv0fY71XrD3ZmPJDg"
const tokenGoogle = "AIzaSyCXDmzBDlnV7R1Yg1V9q0Hd9Y6aEqvpHEs"
export const tiles = [
    {
        name: "Google Híbrido",
        checked: false,
        url: "//mt.google.com/vt/lyrs=y&x={x}&y={y}&z={z}",
    },
    {
        name: "Google Satellite",
        checked: true,
        url: "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidGl3ZWJ0b3VjaCIsImEiOiJjbGx2YjgxcWQwMWJ3M2RvNzRybHltZXV2In0.nr6O_d8Grk9G6tYONc1NSQ",
    },
    {
        name: "Google Terreno",
        checked: false,
        // url: `https://tile.googleapis.com/v1/createSession?key=${tokenMapBox}`,
        url: "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}",
    },
    {
        name: "Google Roteiro",
        checked: false,
        url: "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}",
    },
    {
        name: "Mapbox",
        checked: false,
        url: `https://api.mapbox.com/v4/mapbox.streets-satellite/{z}/{x}/{y}.png?access_token=${tokenMapBox}`,
    },
    {
        name: "Open Street",
        checked: false,
        url: "//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    }
];

