import { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import { LineKey } from "../../../beans/enumerators";
import useLoadingContext from "../../../utils/hooks/useLoading";
import { ListResponse } from "../../../utils/interfaces/response";
import DashBoardSideBars from "../components/DashBoardSideBars";
import FilterModal from "./components/FilterModal";
import { FirstLine } from "./components/FirstLine";
import { FourthLine } from "./components/FourthLine";
import { SecondLine } from "./components/SecondLine";
import { ThirdLine } from "./components/ThirdLine";
import { DashboardInterface, FilterDashboard } from "./interfaces";
import { getDashboardData } from "./services";

interface DashboardProps {

}
export default function Dashboard({ }: DashboardProps) {

    const { setIsLoading } = useLoadingContext();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [lines, setLines] = useState<DashboardInterface | null>(null)
    const [showSidebar, setShowSidebar] = useState(true);
    const [filters, setFilters] = useState<FilterDashboard>({
        occupationAreas: [],
        layers: [],
    })
    const [selectedLayer, setSelectedLayer] = useState<Array<ListResponse>>([]);
    const [selectedOcuppationArea, setSelectedOcuppationArea] = useState<Array<ListResponse>>([]);

    useEffect(() => {
        screenUpdate(filters)
    }, [filters])


    async function screenUpdate(filters?: FilterDashboard) {
        setIsLoading(true)
        await getDashboardData(filters).then(setLines).catch((error) => {
            setIsLoading(false)
        })
        setIsLoading(false)
    }

    return (
        <>
            <Helmet title="Estatisticas" />
            <Row className="dashboard">
                <DashBoardSideBars
                    navigateTo={'estatisticas'}
                    setShowSidebar={setShowSidebar}
                    showSidebar={showSidebar}
                />
                <Col className={`dashboardCards col-auto col-lg-10 col-md-9 ${showSidebar ? 'col-sm-9' : 'col-sm-11'} dashboardBody listSize`}>
                    <Row>
                        <Col>
                            <Card className="filterCard">
                                <Row>
                                    <Col className="col-auto col-lg-8" >
                                        <span className="cardTitle ">Planilha de dados criados com base nos filtros do maps</span>
                                        <p className="cardSubtitle">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis massa vel luctus pharetra.</p>
                                    </Col>
                                    <Col auto className="filterButtonDiv  ">
                                        <Button className="primary-btn" onClick={() => setOpenModal(true)}>FILTROS</Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    {!!lines && lines.lines.map((line, lineIndex) => {
                        if (line.key === LineKey.firstLine) {
                            return (
                                <Row className="mt-2" key={`firstLine-${lineIndex}`}>
                                    {!!line.cards.length && line.cards.map((item, itemIndex) => {
                                        return (
                                            <FirstLine
                                                key={`firstLineCard-${itemIndex}`}
                                                name={item.name}
                                                value={item.value}
                                                description={item.description}
                                                flag={item.flag}
                                            />
                                        )
                                    })}
                                </Row>
                            )
                        } else if (line.key === LineKey.secondLine) {
                            return (
                                <Row className="mt-2" key={`secondLine-${lineIndex}`}>
                                    {!!line.cards.length && line.cards.map((item, itemIndex) => {
                                        return (
                                            <SecondLine
                                                key={`secondLineCard-${itemIndex}`}
                                                name={item.name}
                                                description={item.description}
                                                items={item.items}
                                            />
                                        )
                                    })}
                                </Row>
                            )
                        } else if (line.key === LineKey.thirdLine) {
                            return (
                                <Row className="mt-3" key={`thirdLine-${lineIndex}`}>
                                    <Col>
                                        <Card className="thirdLine">
                                            <Row className="dashboardRowValues">
                                                {!!line.cards.length && line.cards.map((item, itemIndex) => {
                                                    return (
                                                        <ThirdLine
                                                            key={`thirdLineCard-${itemIndex}`}
                                                            name={item.name}
                                                            value={item.value}
                                                            flag={item.flag}
                                                        />
                                                    );
                                                })}
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            )
                        } else if (line.key === LineKey.fourthLine) {
                            return (
                                <Row className="mt-3" key={`fourthLine-${lineIndex}`}>
                                    {!!line.cards.length && line.cards.map((item, itemIndex) => {
                                        return (
                                            <FourthLine
                                                key={`fourthLineCard-${itemIndex}`}
                                                title={item.title}
                                                subTitle={item.subTitle}
                                                info={item.info}
                                                type={item.key ? LineKey.TimePrevision : itemIndex}
                                            />
                                        )
                                    })}
                                </Row>
                            )
                        }
                    })}
                </Col>
            </Row>
            {!!openModal && <FilterModal
                setFilters={setFilters}
                openModal={openModal}
                setOpenModal={setOpenModal}
                selectedLayer={selectedLayer}
                setSelectedLayer={setSelectedLayer}
                selectedOcuppationArea={selectedOcuppationArea}
                setSelectedOcuppationArea={setSelectedOcuppationArea}
            />
            }
        </>
    )
}