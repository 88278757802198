import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Col, FormControl, InputGroup, Row } from 'react-bootstrap';
import { useMap } from "react-leaflet";
import CircleXIcon from "../../../assets/img/circle-xmark-regular.svg";
import FilterIcon from "../../../assets/img/filter.png";
import LayerIcon from "../../../assets/img/layersMap.png";
import MarkerIcon from "../../../assets/img/marker.png";
import { mapSideBarContextEnum } from "../../../beans/enumerators";
import CustomAlert from "../../../components/Alert";
import Icon from "../../../components/Icon";
import NavbarComponent from "../../../components/navbar/NavbarSimple";
import NavbarUser from "../../../components/navbar/NavbarUser";
import useSideBarContext from "../../../utils/hooks/useSideBarMap";

interface MapDrawToolsProps {
    openDrawOptions: boolean;
    setOpenDrawOptions: React.Dispatch<React.SetStateAction<boolean>>;
    mapRef: any
}

const MapButtons: React.FC<MapDrawToolsProps> = ({ openDrawOptions, setOpenDrawOptions }) => {

    const { value, setValue } = useSideBarContext();
    const [openSearch, setOpenSearch] = useState<boolean>(false);
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const [alertText, setAlertText] = useState<string>('');
    const [searchValue, setSearchValue] = useState("");
    const map = useMap();

    function handleClose() {
        setOpenAlert(false)
        setAlertText('')
    }

    function handleSearch() {
        if (searchValue === '') {
            setOpenAlert(true)
            setAlertText("É necessário passar uma latitude e uma longetude para realizar a procura.")
        } else {
            var latlngArray = searchValue.split(',')
            var lat = Number(latlngArray[0])
            var lng = Number(latlngArray[1])
            if (Number.isNaN(lat) || Number.isNaN(lng)) {
                setOpenAlert(true)
                setAlertText("É necessário passar uma latitude/longetude correta para realizar a procura.")
            } else {
                map.setView([lat, lng]);
            }
        }
    }

    return (
        <>
            <Row className='noMarginTop justify-content-between'>
                <Col lg={12} className="menuExpand">
                    <NavbarComponent />
                </Col>
                <Col className={`${!!openSearch ? 'col-lg-12' : 'col-auto'} ${!!value && value !== mapSideBarContextEnum.shape && 'openedSidebar'}`}>
                    {!openSearch &&
                        <div className="mapbuttons">
                            <Button onClick={() => setOpenSearch(!openSearch)} className="no-bg">
                                <FontAwesomeIcon icon={faSearch} size='lg' />
                            </Button>
                        </div>}

                    {!!openSearch &&
                        <InputGroup className="searchButton">
                            <FormControl
                                placeholder="Digite as cordenadas (lat,lng)"
                                value={searchValue}
                                className="border"
                                onChange={(e) => setSearchValue(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                            />
                            <Button onClick={handleSearch} className="bg-white openSearch">
                                <FontAwesomeIcon icon={faSearch} size='lg' />
                            </Button>
                            <Button variant="outline-dark"
                                className="btn-header-search  border" onClick={() => { setSearchValue(""); setOpenSearch(!openSearch); }}>
                                <Icon icon={CircleXIcon} size={'20px'} />
                            </Button>
                        </InputGroup>}
                </Col>
            </Row>
            <div className={`map-menu-user ${value && 'noUserMenu'} ${value === mapSideBarContextEnum.shape && 'userMenu'}`}>
                <NavbarUser />
            </div>

            <Row className={`${!!value && value !== mapSideBarContextEnum.shape && 'd-none'}`}>
                <Col>
                    <div className={`mt-2 toolBar ${!openDrawOptions ? "normalTb" : "extendedTb"}`}>
                        <Button onClick={() => setOpenDrawOptions(!openDrawOptions)} className="no-bg mt-2">
                            <Icon icon={!openDrawOptions ? MarkerIcon : CircleXIcon} size='23px' />
                        </Button>
                        {!!openDrawOptions ?
                            <hr className="mx-2" />
                            :
                            <>
                                <Button onClick={() => setValue('layers')} className="no-bg mt-2">
                                    <Icon icon={LayerIcon} size='23px' />
                                </Button>
                                <Button className="no-bg mt-2" onClick={() => setValue('filters')}>
                                    <Icon icon={FilterIcon} size='23px' />
                                </Button>
                            </>}
                    </div>
                </Col>
            </Row>
            <CustomAlert
                message={alertText}
                show={openAlert}
                onCancel={handleClose}
                onHide={handleClose}
            />
        </>
    )
}
export default MapButtons