import React, { useEffect, useState } from "react";
import { getPollingStatus } from "../../pages/Dashboard/Polling/services/shapePolling";
import { toast } from "react-toastify";
import { alertType, intervalTimer } from "../../beans/enumerators";

const initialState = {
    value: '',
    setValue: (value: string) => { },
};

const PollingVerifierContext = React.createContext(initialState);

interface PollingVerifierProps {
    children: React.ReactNode;
}

function PollingVerifierProvider({ children }: PollingVerifierProps) {
    const [value, setValue] = useState('');

    useEffect(() => {
        if (value) {
            const interval = setInterval(() => {
                getPollingStatus(value).then((response) => {
                    if (response.data) {
                        if (response.data.status === 'sucesso') {
                            clearInterval(interval);
                            toast.success(alertType.pollingSuccess);
                        } else if (response.data.status === 'erro') {
                            clearInterval(interval);
                            toast.error(alertType.fileError);
                        }
                    }
                });
            }, intervalTimer.ten);

            return () => clearInterval(interval);
        }
    }, [value]);



    return (
        <PollingVerifierContext.Provider
            value={{ value, setValue, }}
        >
            {children}
        </PollingVerifierContext.Provider>
    );
}

export { PollingVerifierContext, PollingVerifierProvider };

