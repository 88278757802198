import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

import PageTitle from "../../../../components/PageTitle";
import MultiSelect from "../../../../components/SelectSearch";
import { ListResponse } from "../../../../utils/interfaces/response";
import { getOccupationAreaToSelect } from "../../../map/OccupationArea/services";
import { getLayersList } from "../../Layer/services/layers";
import { FilterDashboard } from "../interfaces";
import useLoadingContext from "../../../../utils/hooks/useLoading";

interface FilterModalProps {
    setFilters: Dispatch<SetStateAction<FilterDashboard>>
    openModal: boolean
    setOpenModal: Dispatch<SetStateAction<boolean>>
    selectedLayer: ListResponse[]
    setSelectedLayer: Dispatch<SetStateAction<ListResponse[]>>
    setSelectedOcuppationArea: Dispatch<SetStateAction<ListResponse[]>>
    selectedOcuppationArea: ListResponse[]
}

const FilterModal: React.FC<FilterModalProps> = ({ openModal, setOpenModal, setFilters, selectedLayer, setSelectedLayer, selectedOcuppationArea, setSelectedOcuppationArea }) => {


    const { setIsLoading } = useLoadingContext();
    const [occupationAreaList, setOccupationAreaList] = useState<Array<ListResponse>>([])
    const [layerList, setLayerList] = useState<Array<ListResponse>>([])



    async function handleSubmit(event?: React.FormEvent<any>) {
        event && event.preventDefault();
        const filters = {
            layers: selectedLayer.map((item) => item.value),
            occupationAreas: selectedOcuppationArea.map((item) => item.value),
        }
        setFilters(filters)

    }



    useEffect(() => {
        getOccupationAreaToSelect().then(setOccupationAreaList).catch(() => {
            setIsLoading(false)
        })
        getLayersList().then(setLayerList).catch(() => {
            setIsLoading(false)
        })
    }, [])

    function handleCloseModal() {
        handleSubmit()
        setOpenModal(false)
    }


    return (
        <Modal dialogClassName="ts-modal large-modal" show={openModal} onHide={handleCloseModal} >
            <Modal.Header closeButton>
                <Modal.Title>
                    <PageTitle text="FILTROS" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit} className="px-2" >
                    <Row>

                        <Col lg={6} className="mt-4">
                            <MultiSelect
                                options={layerList}
                                placeholder="Selecione as camadas"
                                selectedOptions={selectedLayer}
                                setSelectedOptions={setSelectedLayer}
                                labelText={'Camada'}
                                withList
                            />
                        </Col>

                        <Col lg={6} className="mt-4">
                            <MultiSelect
                                options={occupationAreaList}
                                placeholder="Selecione as áreas de ocupação"
                                selectedOptions={selectedOcuppationArea}
                                setSelectedOptions={setSelectedOcuppationArea}
                                labelText={'Área de atuação'}
                                withList
                            />
                        </Col>
                    </Row>
                    <Row className="modal-footer ">
                        <Col className="d-flex justify-content-end">
                            <Button className="redbg ts-link-icon mx-2" onClick={(e) => { handleSubmit(e); handleCloseModal() }}> CANCELAR</Button>
                            <Button type="submit" className="primary-btn modalSubmi-btn">FILTRAR</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal >


    )
}

export default FilterModal;