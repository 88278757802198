import shapeApi from "../../../../utils/axios";

export async function getPollingStatus(protocolId: string) {
    return await shapeApi.get(`/shape/import-shape/polling?protocol=${protocolId}`).then(response => {
        return response;
    })
}

export async function getPollingList(data: any) {
    return await shapeApi.get(`/shape/shape-polling/list?` + new URLSearchParams(data).toString()).then(response => {
        return response.data;
    })
}