import React, { useEffect, useState } from "react";

const initialValue = '';

const initialState = {
    value: initialValue,
    setValue: (value: string) => { },
};

const FilterContext = React.createContext(initialState);

interface FilterProviderProps {
    children: React.ReactNode;
}

function FilterProvider({ children }: FilterProviderProps) {
    const [value, setValue] = useState('');

    return (
        <FilterContext.Provider
            value={{
                value,
                setValue,
            }}
        >
            {children}
        </FilterContext.Provider>
    );
}

export { FilterContext, FilterProvider };

