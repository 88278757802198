import React from 'react';
import ReactApexChart from 'react-apexcharts';

interface RadarChartProps {
    colors: Array<string>;
}


const RadarChart = ({ colors }: RadarChartProps) => {

    const series = [
        {
            name: 'Series 1',
            data: [80, 50, 30, 40, 100, 20],

        },
        {
            name: 'Series 2',
            data: [20, 30, 40, 80, 20, 80],

        },
        {
            name: 'Series 3',
            data: [44, 76, 78, 13, 43, 10],
        },
        {
            name: 'Series 4',
            data: [77, 26, 48, 53, 93, 11],
        },
        {
            name: 'Series 5',
            data: [1, 76, 44, 13, 22, 87],
        }]

    const options: any = {
        chart: {
            height: 231,
            dropShadow: {
                enabled: true,
                blur: 1,
                left: 1,
                top: 1,
            },
        },
        stroke: {
            width: 0.5,
        },
        colors: colors,
        fill: {
            colors: colors,
            opacity: 0.2,
        },
        markers: {
            size: 0,
        },
        legend: {
            offsetY: 20,
            position: 'right',
            fontSize: '10px',
            itemMargin: {
                horizontal: 0,
                vertical: 2
            },
            markers: {
                height: 8,
                width: 15,
                radius: 0,
            }
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul'],
        },
    }

    return (
        <div id="chart">
            <ReactApexChart options={options} series={series} type="radar" />
        </div>
    );
};

export default RadarChart;
