import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";

const initialValue: boolean = false;

const initialState = {
    isLoading: initialValue,
    setIsLoading: (value: boolean) => { },
};

const LoadingContext = React.createContext(initialState);

interface LoadingProviderProps {
    children: React.ReactNode;
}

function LoadingProvider({ children }: LoadingProviderProps) {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <LoadingContext.Provider value={{ isLoading, setIsLoading, }}>

            {!!isLoading && (<Loader />)}
            {children}
        </LoadingContext.Provider>
    );
}

export { LoadingContext, LoadingProvider };

