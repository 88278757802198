import shapeApi from "../../../../utils/axios";
import { OccupationAreaEdit } from "../interfaces";

export async function getOccupationAreaList(): Promise<any> {
    return await shapeApi.get(`/occupation-area/list`).then(response => {
        return response.data
    })
}

export async function getOccupationAreaToSelect(): Promise<any> {
    return await shapeApi.get(`/occupation-area/list-to-select`).then(response => {
        return response.data
    })
}

export async function getOccupationArea(id: string): Promise<OccupationAreaEdit> {
    return await shapeApi.post(`/occupation-area/findById/`, { id: id }).then(response => {
        return response.data
    })
}

export async function createOccupationArea(data: any): Promise<any> {
    return await shapeApi.post(`/occupation-area/save/`, data).then(response => {
        return response.data
    })
}

export async function updateOccupationArea(data: any): Promise<any> {
    return await shapeApi.put(`/occupation-area/update`, data).then(response => {
        return response
    })
}

export async function deleteOccupationArea(id: any): Promise<any> {
    return await shapeApi.delete(`/occupation-area/delete/?id=` + id).then(response => {
        return response
    })
} 