import React from "react";
import logo from "../assets/img/logo.jpg" 

const Logo = () => (
  <React.Fragment>
    <img
      src={logo}
      alt="Logo"
      className="img-fluid mx-auto mb-5 d-block img-logo"
    />
    <hr />
  </React.Fragment>
);

export default Logo;
