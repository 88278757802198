import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { toast } from 'react-toastify';
import { alertType, itens_per_page } from "../../../beans/enumerators";
import CustomAlert from "../../../components/Alert";
import PaginationCustom from "../../../components/lists/Pagination";
import useLoadingContext from "../../../utils/hooks/useLoading";
import DashBoardSideBars from "../components/DashBoardSideBars";
import ModalLayerComponent from "./components/ModalLayerComponent";
import { Layer } from "./interfaces";
import { deleteLayer, getLayers } from "./services/layers";

const LayerPage = () => {

  const { setIsLoading } = useLoadingContext();
  const [nList, setNList] = useState<number>(0);
  const [pagination, setPagination] = useState<number>(1);
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openAlert, setOpenAlert] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<any>('')
  const [layerList, setLayerList] = useState<Array<Layer>>([])
  const [showSidebar, setShowSidebar] = useState(true);
  const [filters, setFilters] = useState<any>({
    pagination: "1",
  });

  function verifyFilters(key: string, newValue: string) {
    let newFilters = filters;
    newFilters[key] = newValue;
    if (key !== "pagination") newFilters["pagination"] = "1";
    setFilters(newFilters);
    screenUpdate()
  }

  useEffect(() => {
    screenUpdate()
  }, [])

  async function screenUpdate() {
    let data = {
      page: filters.pagination,
      limit: itens_per_page.default,
    }
    setIsLoading(true)
    await getLayers(data).then((response) => {
      if (response) {
        setLayerList(response.result)
        setNList(response.total)
        response.total > 0 ? setPagination(Math.ceil(response.total / itens_per_page.default)) : setPagination(0);
      }
    }).catch(() => {
      setIsLoading(false)
    })
    setIsLoading(false)
  }

  async function handleDelete() {
    setIsLoading(true)
    await deleteLayer(selectedItem).then(() => toast.success(alertType.deleted)).catch(() => {
      setIsLoading(false)
    })
    screenUpdate()
    handleCloseAlert()
    setIsLoading(false)
  }

  function handleCloseAlert() {
    setOpenAlert(false)
    setSelectedItem('')
  }

  return (
    <>
      <Helmet title="Camadas" />
      <Row className="dashboard">
        <DashBoardSideBars
          navigateTo={'camadas'}
          setShowSidebar={setShowSidebar}
          showSidebar={showSidebar}
        />

        <Col className={`dashboardCards card col-auto col-lg-10 col-md-9 ${showSidebar ? 'col-sm-9' : 'col-sm-11'} dashboardBody listSize`}>
          <Row>
            <Col className="col-10 col-sm-7 col-md-9 col-lg-10">
              <h2>Tabela de camadas</h2>
            </Col>
            <Col className="m-xs-4 col-5 col-md-3 col-lg-2">
              <Button className="primary-btn" onClick={() => setOpenModal(true)}>ADICIONAR</Button>
            </Col>
          </Row>
          <Table className="mt-5 trBorder ">
            <thead>
              <tr>
                <th>
                  <span>Nome da camada</span>
                </th>
                {/* <th>Company</th> */}
              </tr>
            </thead>
            <tbody>
              {layerList.map((item, key) => (
                <tr className="trBorder" key={key}>
                  <td>
                    <span>{item.name}</span>
                  </td>
                  {/* <td>{item.company}</td> */}
                  <td className="d-flex justify-content-end">
                    <FontAwesomeIcon icon={faTrash} className="align-middle mx-2" onClick={() => { setOpenAlert(true); setSelectedItem(item.id) }} />
                    <FontAwesomeIcon icon={faPencilAlt} className="align-middle" onClick={() => { setOpenModal(true); setSelectedItem(item.id) }} />

                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <PaginationCustom
            pagination={pagination}
            filter={filters.pagination}
            verifyFilters={verifyFilters}
            total={nList}
          />


        </Col>
      </Row >
      <CustomAlert
        message={alertType.delete + 'essa camada?'}
        show={openAlert}
        onYes={handleDelete}
        onNo={handleCloseAlert}
        onHide={handleCloseAlert}
      />

      <ModalLayerComponent
        openModal={openModal}
        setOpenModal={setOpenModal}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        screenUpdate={screenUpdate}
      />
    </>

  );
};

export default LayerPage;
